import React, {useEffect, useState} from 'react'
import {KTSVG} from 'src/marketConnect/helpers'
import AlertModal from 'src/utils/modals/AlertModal'
import ConnectPlatformBox from './ConnectPlatformBox'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Spinner from 'react-bootstrap/Spinner'
import {toast} from 'react-toastify'
import {Link, useNavigate, useSearchParams, useParams} from 'react-router-dom'
import {getApproveSubscriptionAsync} from 'src/redux/actions/pricingActions'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import {setShopifyConnectFirstTimeMessageShow} from 'src/redux/slices/authSlice'
import {
  fetchConnectedPlatformAsync,
  deletePlatformDataAsync,
  shopifyappUninstallAsync,
} from 'src/redux/actions/platformActions'
import {PlatformDataType} from 'src/interfaces/Platform.interface'

const ConnectedPlatforms = () => {
  const navigate = useNavigate()
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
  const [deleting, setDeleting] = useState(false)
  const [selectedPlatformDelete, setSelectedPlatformDelete] = useState<PlatformDataType | null>(
    null
  )
  const {fetchedConnectedPlatformLoading} = useAppSelector((state) => state.fetchConnectedPlatform)
  const fetchedConnectedPlatformData = useAppSelector(
    (state) => state.fetchConnectedPlatform.fetchedConnectedPlatformData
  )
  const {fetchedUserAccountData} = useAppSelector((state) => state.userAccountData)
  const [isVisibleAlertModel, setIsVisibleAlertModel] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const charge_id = searchParams.get('charge_id')
  const success = searchParams.get('success')
  const user_id = searchParams.get('user_id')
  const {selectedPlatformId} = useParams()

  useEffect(() => {
    if (charge_id) {
      const params = {charge_id, platform_id: selectedPlatformId ?? 'WOOCOMMERCE'}
      dispatch(getApproveSubscriptionAsync(params)).then((response) => {
        if (response?.payload?.status_code === 201 && response?.payload?.data) {
          toast.success(response?.payload?.message)
          setIsVisibleAlertModel(true)
        } else {
          toast.error(response?.payload?.message)
          setIsVisibleAlertModel(true)
        }
      })
    }
  }, [charge_id, selectedPlatformId, dispatch])

  const onDeleteStripe = (platform: PlatformDataType) => {
    setSelectedPlatformDelete(platform)
    setShowAlertModal(true)
  }

  const onDeleteConfirmed = async () => {
    if (selectedPlatformDelete?.id !== undefined) {
      setDeleting(true)
      try {
        if (fetchedUserAccountData?.source === 'SHOPIFY') {
          const response = await dispatch(deletePlatformDataAsync({id: selectedPlatformDelete.id}))
          toast.success(response?.payload?.message)
          setDeleting(false)
          setShowAlertModal(false)
          await dispatch(fetchConnectedPlatformAsync())
        } else {
          await dispatch(deletePlatformDataAsync({id: selectedPlatformDelete.id}))
          toast.success('Platform deleted successfully!', {autoClose: 3000})
          setDeleting(false)
          setShowAlertModal(false)
          await dispatch(fetchConnectedPlatformAsync())
        }
      } catch (error) {
        setDeleting(false)
        toast.error('Error during deletion')
      }
    } else {
      toast.warning('Platform id is undefined.')
    }
  }

  return (
    <>
      {fetchedConnectedPlatformLoading ? (
        <div className='d-flex align-items-center justify-content-center h-100'>
          <h1>
            <Spinner animation='border' variant='primary' />
          </h1>
        </div>
      ) : (
        <div className='py-4 pt-0'>
          {fetchedConnectedPlatformData?.length > 0 ? (
            fetchedConnectedPlatformData.map((platform: any) => (
              <ConnectPlatformBox
                key={platform.id}
                platform={platform}
                onDelete={() => onDeleteStripe(platform)}
              />
            ))
          ) : (
            <div className='alert bg-light-primary d-flex align-items-center p-5 shadow-sm'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-8'>
                <KTSVG
                  path='media/icons/duotune/communication/com009.svg'
                  className='svg-icon-muted svg-icon-3hx'
                />
              </span>

              <div className='d-flex flex-column'>
                <h5 className='mb-1'>No Platform is connected yet</h5>
                <span>
                  Please connect a platform. Click here to connect{' '}
                  <Link to={'/dashboard'}>
                    <KTSVG
                      path='media/icons/duotune/general/gen016.svg'
                      className='svg-icon-primary svg-icon-hx'
                    />
                  </Link>
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      <AlertModal
        isVisible={showAlertModal}
        inProgress={deleting}
        successBtnLabel={deleting ? 'Deleting...' : 'Confirm Delete'}
        closeModal={() => setShowAlertModal(false)}
        onDeleteConfirmed={onDeleteConfirmed}
        selectedPlatformDelete={selectedPlatformDelete ?? undefined}
      />

      <CommonAlertModal
        title={
          success && user_id
            ? 'WooCommerce is successfully connected'
            : 'Your request to change current plan has been completed. Your new plan is active.'
        }
        subtitle=''
        onSuccess={() => {
          setIsVisibleAlertModel(false)
          if (fetchedUserAccountData?.source === 'WOOCOMMERCE') {
            if (!fetchedConnectedPlatformData?.length) {
              dispatch(
                setShopifyConnectFirstTimeMessageShow({
                  show: true,
                  message: 'Go ahead & Connect WooCommerce as your Primary store',
                })
              )
            }
            navigate('/dashboard')
          }
        }}
        onCancel={() => {
          setIsVisibleAlertModel(false)
        }}
        cancelBtnTitle='Close'
        isVisible={isVisibleAlertModel}
        showCloseButton={false}
        iconPath='/media/icons/duotune/general/gen043.svg'
        iconClass='svg-icon-success svg-icon-4hx'
        successBtnClasses='btn btn-sm btn-success m-2'
        successBtnTitle='Ok'
      />
    </>
  )
}

export default ConnectedPlatforms
