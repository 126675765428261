import React, {FC, useEffect, useState} from 'react'
import {
  Button,
  Card,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from 'react-bootstrap'
import AddNewPriceTemplateModal from './AddNewPriceTemplateModal'
import AddNewTitleDescriptionModal from './AddNewTitleDescriptionModal'
import AddNewRecipeModal from './AddNewRecipeModal'
import {useAppDispatch} from 'src/redux/storeHooks'
import {PlatformDataType} from 'src/interfaces/Platform.interface'
import {
  deleteCategoryTemplateDataAsync,
  deleteRecipeAsync,
  deleteTitleDescriptionTemplateAsync,
  getMoreMappingAsync,
  getPlatformCategoryTemplateDataAsync,
  getPlatformTitleDescriptionTemplateAsync,
  getRecipesAsyncUsingPlatformId,
} from 'src/redux/actions/templateAndPricingActions'

import useAppToast from 'src/hooks/useAppToast'
import {
  deletPlatformPriceAsync,
  getPlatformPriceAsync,
} from 'src/redux/actions/productPricingAction'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowDown} from '@fortawesome/free-solid-svg-icons'
import AddNewCategoryTemplateModal from './AddNewCategoryTemplateModal'
import {toast} from 'react-toastify'
import {
  CATEGORY_ITEM,
  MoreFieldMappingItem,
  MoreMapping_Item_On_Get,
  PRICE_TEMPLATE_ITEM,
  RECIPE,
  TITLE_DESCRIPTION_TEMPLATE_ITEM,
} from 'src/interfaces/Template.interface'
import TemplateListItemcopy from './TemplateListItemcopy'
import {getPlatformFieldsAsync} from 'src/redux/actions/productActions'
import AddNewMoreMappingTemplateModal from './AddNewMoreMappingTemplateModal'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import MoreMappingItem from './MoreMappingItem'

type TemplateComponentProps = {
    item:PlatformDataType
    platformPricingTemplateLoading:boolean,
    setShowRefreshModal:React.Dispatch<React.SetStateAction<boolean>>
    setRefreshPlatformId:React.Dispatch<React.SetStateAction<number | null>>
}

const TemaplateComponent:FC<TemplateComponentProps> = ({
    item,
    platformPricingTemplateLoading,
    setShowRefreshModal,
    setRefreshPlatformId,
  }) => {
    
    const [showAddTitleDescriptionModal, setShowAddTitleDescriptionModal] = useState<boolean>(false)
    const [selectedPlatformId, setSelectedPlatformId] = useState<any>(item)
    const [showTable, setShowTable] = useState(false)
    const [categoryTemplateForUpdate, setCategoryTemplateForUpdate] = useState<CATEGORY_ITEM | null>(
      null
    ) // State to store selected template data
  
    const [activeTab, setActiveTab] = useState({tab1: true, tab2: false})
    const [priceingTabel, setPriceingTabel] = useState<boolean>(false)
    const [showPriceTemplateModal, setShowPriceTemplateModal] = useState<boolean>(false)
    const [updatePriceItem, setUpdatePriceItem] = useState<PRICE_TEMPLATE_ITEM | null>(null)
    const [showAddRecipeModal, setShowAddRecipeModal] = useState<boolean>(false)
    const [priceTemplateList, setPriceTemplateList] = useState<PRICE_TEMPLATE_ITEM[] | []>([])
    const [priceTemplatePagination, setPriceTemplatePagination] = useState({
      page: 1,
      limit: 10,
      count: 0,
      previous: null,
      next: null,
    })
   
    const [paginationCategoryData, setPaginationCategoryData] = useState({
      page: 1,
      limit: 10,
      count: 0,
      previous: null,
      next: null,
    })
    const [showCategoryData1, setShowCategoryData1] = useState(false)
    const [showMoreMappingTemplateTable, setshowMoreMappingTemplateTable] = useState(false)
    const [showRecipeData, setShowRecipeData] = useState(false)
    const [loadRecipeData, setLoadRecipeData] = useState<boolean>(false)
    const [platformCategories, setPlaformCategories] = useState<CATEGORY_ITEM[] | []>([])
    const [loadCategorieData, setloadCategorieData] = useState<boolean>(false)
    const [platformRecipes, setPlatformRecipes] = useState<any[]>([])
    const [recipePagination, setRecipePagination] = useState({
      page: 1,
      limit: 10,
      count: 0,
      previous: null,
      next: null,
    })
    const [updatingRecipeData, setUpdatingRecipeData] = useState<RECIPE | null>(null)
    const [showCategoryTemplateModal, setShowCategoryTemplateModal] = useState<boolean>(false)
    const [loadTitleDescription, setLoadTitleDescription] = useState(false)
    const [titleDescriptionList, setTitleDescriptionList] = useState<
      TITLE_DESCRIPTION_TEMPLATE_ITEM[] | []
    >([])
    const [updatingTitleDescriptionData, setUpdatingTitleDescriptionData] =
      useState<TITLE_DESCRIPTION_TEMPLATE_ITEM | null>(null)
    const [titleDescriptionPagination, setTitleDescriptionPagination] = useState({
      page: 1,
      limit: 5,
      count: 0,
      previous: null,
      next: null,
    })
  
    const [moreFieldMappingData, setMoreFieldMappingData] = useState<MoreFieldMappingItem[]>([])
    const [showMoreMapping, setShowMoreMapping] = useState<boolean>(false)
    const [showMoreMappingTemplateModal, setShowMoreMappingTemplateModal] = useState<boolean>(false)
    const [selectedMappingItem, setSelectedMappingItem] = useState<MoreFieldMappingItem | null>(null)
    useEffect(() => {
      if (selectedMappingItem?.id) {
        getMoreMappingDataAsync()
      }
    }, [selectedMappingItem]) // Dependency array includes selectedMappingItem
  
    const [moreMappingsPagination, setMoreMappingsPagination] = useState({
      page: 1,
      limit: 5,
      count: 0,
      previous: null,
      next: null,
    })
    const [getMoreMappingData, setGetMoreMappingData] = useState<MoreMapping_Item_On_Get[] | []>([])
    const [getShowMoreMappingData, setGetShowMoreMappingData] = useState(false)
  
    const [updatingMoreMappingData, setUpdatingMoreMappingData] = useState<MoreMapping_Item_On_Get | null>(
      null
    )
    const [mappingLoading, setMappingLoading] = useState<boolean>(false)
    const [showAlertModalTitle, setShowAlertModalTitle] = useState<boolean>(false)
    const [showAlertModalPrice, setShowAlertModalPrice] = useState<boolean>(false)
    const [showAlertModalCategory, setShowAlertModalCategory] = useState<boolean>(false)
    const [showAlertModalListing, setShowAlertModalListing] = useState<boolean>(false)
    const [selectedTemplateDataForDelete, setSelectedTemplateDataForDelete] = useState<null|any>()

    const dispatch = useAppDispatch()
  
    const {successToast, warningToast} = useAppToast()
  
   
  
    const onClickAddNewRecipeModal = () => {
      setUpdatingRecipeData(null)
      setShowAddRecipeModal(true)
    }
  
  
    const handleUpdateCategory = (templateData) => {
      setCategoryTemplateForUpdate(templateData)
      setShowCategoryTemplateModal(true)
    }
  
    
  
    const handleUpdatePrice = (templateData) => {
      setUpdatePriceItem(templateData)
      setShowPriceTemplateModal(true)
    }
  
    const handleCloseModal = () => {
      setShowAddTitleDescriptionModal(false)
      setUpdatingTitleDescriptionData(null)
    }
  
    const getTitleDescriptionListAsync = (page = 1) => {
      setShowTable(true)
      setLoadTitleDescription(true)
      dispatch(
        getPlatformTitleDescriptionTemplateAsync({
          platform_id: item.id+'',
          paginationdata: {page: page, limit: 5},
        })
      ).then((response) => {
        setTitleDescriptionList(response.payload.results)
        setTitleDescriptionPagination((pre) => ({
          ...pre,
          count: response.payload.count,
          previous: response.payload.previous,
          next: response.payload.next,
        }))
        setLoadTitleDescription(false)
      })
    }
  
    const getRecipesAsync = (page = 1) => {
      setShowRecipeData(true)
      setLoadRecipeData(true)
      dispatch(
        getRecipesAsyncUsingPlatformId({platformId: item.id!+'', otherParams: {page: page, limit: 5}})
      ).then((response) => {
        setPlatformRecipes(response.payload.results)
        setRecipePagination((pre) => ({
          ...pre,
          page: page,
          count: response.payload.count,
          previous: response.payload.previous,
          next: response.payload.next,
        }))
        setLoadRecipeData(false)
      })
    }
  
    const getPriceTemplateAsync = (page = 1) => {
      setPriceingTabel(true)
      dispatch(
        getPlatformPriceAsync({platform_id: item.id!+'', paginationdata: {page: page, limit: 5}})
      ).then((response) => {
        setPriceTemplateList(response.payload.results)
        setPriceTemplatePagination((pre) => ({
          ...pre,
          page: page,
          count: response.payload.count,
          previous: response.payload.previous,
          next: response.payload.next,
        }))
        // setLoadRecipeData(false)
      })
    }
    const callFieldMapping = (item) => {
      setMappingLoading(true)
  
      if (item.id) {
        dispatch(
          getPlatformFieldsAsync({
            platformId: item?.id,
            otherParams: {field_code: '', allowMapViaTemplate: true},
          })
        ).then((response) => {
          if (response?.payload?.length) {
            setMappingLoading(false)
            setShowMoreMapping(!showMoreMapping)
            setMoreFieldMappingData(response?.payload)
          } else {
            setMappingLoading(false)
            warningToast('No additional rule found')
          }
  
          console.log('response of blank mapping ', moreFieldMappingData)
        })
      }
    }
    
  
    const getCategoriesAsync = (page = 1) => {
      setShowCategoryData1(true)
      setloadCategorieData(true)
      dispatch(
        getPlatformCategoryTemplateDataAsync({
          platform_id: item.id!,
          paginationdata: {page: page, limit: 5},
        })
      ).then((response) => {
        setPlaformCategories(response.payload.results)
        setPaginationCategoryData((pre) => ({
          ...pre,
          page: page,
          count: response.payload.count,
          previous: response.payload.previous,
          next: response.payload.next,
        }))
        setloadCategorieData(false)
      })
    }
    const getMoreMappingDataAsync = (page = 1) => {
      setshowMoreMappingTemplateTable(true)
      setGetShowMoreMappingData(true)
      console.log('selectedMappingItem..............=id        ', selectedMappingItem?.id)
      const fieldId = selectedMappingItem?.id
      if (fieldId) {
        //  alert(1)
        dispatch(
          getMoreMappingAsync({
            platform_id: item.id!,
            field_id: fieldId,
            paginationdata: {page: page, limit: 5},
          })
        ).then((response) => {
          setGetMoreMappingData(response.payload.results)
          setGetMoreMappingData(response.payload.results)
          console.log('setGetMoreMappingData', getMoreMappingData)
          setMoreMappingsPagination((pre) => ({
            ...pre,
            page: page,
            count: response.payload.count,
            previous: response.payload.previous,
            next: response.payload.next,
          }))
          setGetShowMoreMappingData(false)
        })
      }
    }


    return (
      <div key={item?.id} className='border border-2 rounded p-4 mb-5 '>
        <Card.Body>
          <div className='col-12  border-right-4 d-flex align-items-center'>
            <div className='p-2 w-100 d-flex justify-content-between'>
              <div className='d-flex align-items-center'>
                {/* <img src={item?.platform_info?.logo_ref} className='h-40px w-40px me-3' alt='Logo' /> */}
                <p
                  className='text-gray-1000 text-hover-primary fs-4 fw-bolder m-0'
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item?.display_name}
                </p>
              </div>
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip>Click here if you are not seeing your newly Custom field or any missing information for mapping</Tooltip>}
               >
                <button className="btn btn-sm btn-primary me-0 me-sm-4 fw-bold" onClick={()=>{
                  setShowRefreshModal(true)
                  setRefreshPlatformId(item?.id!)
                  
                }} >Refresh Fields</button>
              </OverlayTrigger>
            </div>
          </div>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap my-1'>
              <li className='nav-item'>
                <button
                  className={
                    activeTab.tab1
                      ? `nav-link active-tab text-active-primary text-secondary`
                      : `nav-link border-bottom text-secondary`
                  }
                  onClick={() => {
                   
                    setActiveTab({tab1: true, tab2: false})
                    // callFieldMapping(item)
                  }}
                >
                  Listing Rules
                </button>
              </li>
              <li className='nav-item'>
                <button
                  className={
                    activeTab.tab2
                      ? `nav-link active-tab text-active-primary text-secondary`
                      : `nav-link border-bottom text-secondary`
                  }
                  onClick={() => {
                    setActiveTab({tab1: false, tab2: true})
                  }}
                >
                  Listing Plan
                </button>
              </li>
            </ul>
          </div>
        </Card.Body>
        {activeTab.tab1 && (
          <div className='p-0 p-sm-2'>
            <div className=' mt-2'>
              <div className='my-2'>
                <p className='h6 text-secondary  mb-4 '>
                Our portal's Listing Rules feature simplifies your product listings. You can easily map or customize titles and descriptions from your Primary platform to secondary platform, set rules to adjust/increase sales prices, choose appropriate listing categories from Secondary platform, and map required product attributes. This streamlines your product management across multiple channels, saving you time and improving efficiency. You can set a Rule as Default to automatically apply to all listings.  <a href={'https://commercium.tawk.help/article/listing-rules-simplify-your-product-management'} target='_blank' >Read More</a>
                </p>
              </div>
            
              <div className='accordion p-0 pt-2 p-sm-2' id='titleDescriptionAccordion'>
                <div className='accordion-item shadow'>
                  <h2 className='accordion-header' id='titleDescriptionHeading'>
                    <button
                      className='accordion-button '
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={'#titleDescriptionCollapse' + item?.id}
                      aria-expanded='false'
                      aria-controls={'#titleDescriptionCollapse' + item?.id}
                      onClick={() => {
                        setShowTable(!showRecipeData)
                        getTitleDescriptionListAsync(1)
                      }}
                    >
                      Title Description Mapping Rule
                    </button>
                  </h2>
                  <div
                    id={'titleDescriptionCollapse' + item?.id}
                    className='accordion-collapse collapse '
                    aria-labelledby='titleDescriptionHeading'
                    data-bs-parent='#titleDescriptionAccordion'
                  >
                    <div className='accordion-body'>
                      <div className=''>
                        <div className="row pe-sm-0">
                            <div className='col-12 col-sm-6 '>
                            <p className='fw-bold  '>
                                Add Title Description Mapping Rule for {item?.display_name}
                            </p>{' '}
                            </div>
                            <div className='col-12 col-sm-6 d-flex justify-content-end pe-0 mb-3'>
                            <button
                                className='btn btn-primary btn-sm align-self-end'
                                onClick={() => {
                                setUpdatingTitleDescriptionData(null)
                                setShowAddTitleDescriptionModal(true)
                                }}
                            >
                                + Add new
                            </button>
                            </div>
                        </div>
  
                        {showTable ? (
                          <div className='p-0 p-sm-2' >
                            {loadTitleDescription ? (
                              <div className='d-flex align-items-center justify-content-center'>
                                <h1>
                                  <Spinner animation='border' variant='primary' />
                                </h1>
                              </div>
                            ) : (
                              <>
                                {titleDescriptionPagination.count === 0 ? (
                                  // Content to render when recipePagination.count is equal to 0
                                  <p className='mt-3'>No Title Description Mapping Rule found </p>
                                ) : (
                                  // Content to render when recipePagination.count is not equal to 0
                                  <div>
                                      <div className='card-body w-100 py-3'>
                                        {/* begin::Table container */}
                                        <div className='table-responsive '>
                                          {/* begin::Table */}
                                          <table className='table table-striped table-hover table-sm gs-0 gy-4' >
                                            {/* begin::Table head */}
                                            <thead>
                                              <tr className='fw-bold text-muted  border-bottom border-bottom-2'>
                                                <th className='min-w-150px ps-4'>Rule Name</th>
                                                <th className='min-w-100px text-center'>Actions</th>
                                              </tr>
                                            </thead>
                                            {/* end::Table head */}
                                            {/* begin::Table body */}
                                            <tbody>
                                              {titleDescriptionList?.length
                                                ? titleDescriptionList.map((templateData) => (
                                                      <TemplateListItemcopy
                                                        key={templateData.id}
                                                        name={templateData.name}
                                                        // Pass update and delete handler functions here
                                                        onUpdate={() => {
                                                          setUpdatingTitleDescriptionData(
                                                            templateData
                                                          )
                                                          setShowAddTitleDescriptionModal(true)
                                                        }}
                                                        onDelete={() => {
                                                         
                                                          setSelectedTemplateDataForDelete(templateData)
                                                          setShowAlertModalTitle(true)
                                                          // setLoadTitleDescription(true)
                                                   
                                                        }}
                                                      />
                                                  ))
                                                : null}
                                            </tbody>
                                            {/* end::Table body */}
                                          </table>
                                          {/* end::Table */}
                                        </div>
                                        {/* end::Table container */}
                                      </div>
  
                                      <div className='d-flex flex-column'>
                                        <div className='border-bottom my-2 border-2'></div>
                                        <div className='d-flex flex-row justify-content-center mt-2'>
                                          <div className=' m-3'>
                                            <strong>
                                              Total {titleDescriptionPagination.count} Records{' '}
                                            </strong>
                                          </div>
                                          <Button
                                            className='m-2 '
                                            size='sm'
                                            onClick={() => {
                                              setTitleDescriptionPagination((pre) => {
                                                return {
                                                  ...pre,
                                                  page: titleDescriptionPagination.page - 1,
                                                }
                                              })
                                              getTitleDescriptionListAsync(
                                                titleDescriptionPagination.page - 1
                                              )
                                            }}
                                            disabled={titleDescriptionPagination.previous == null}
                                          >
                                            -
                                          </Button>
                                          <Button
                                            className='m-2'
                                            size='sm'
                                            onClick={() => {
                                              setTitleDescriptionPagination((pre) => {
                                                return {
                                                  ...pre,
                                                  page: titleDescriptionPagination.page + 1,
                                                }
                                              })
                                              getTitleDescriptionListAsync(
                                                titleDescriptionPagination.page + 1
                                              )
                                            }}
                                            disabled={titleDescriptionPagination.next == null}
                                          >
                                            +
                                          </Button>
                                        </div>
                                      </div>
                                     
                                  </div>
                                )}
                              </>
                            )}
  
                            {/* <div className='text-center '>
                            <button
                              className='btn btn-primary btn-sm  mt-3'
                              onClick={() => {
                                setShowTable(false)
                              }}
                            >
                              <FontAwesomeIcon icon={faArrowUp} />
                            </button>
                          </div> */}
                          </div>
                        ) : // <div className='text-center'>
                        //   <button
                        //     className='btn btn-primary btn-sm mt-3'
                        //     onClick={() => {
                        //       getTitleDescriptionListAsync(1)
                        //     }}
                        //   >
                        //     <FontAwesomeIcon icon={faArrowDown} />
                        //   </button>
                        // </div>
                        null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div className='accordion p-0 pt-2 p-sm-2' id='priceModifierAccordion'>
                <div className='accordion-item shadow'>
                  <h2 className='accordion-header' id='priceModifierHeading'>
                    <button
                      className='accordion-button'
                      type='button'  
                      data-bs-toggle='collapse'
                      data-bs-target={'#priceModifierCollapse' + item?.id}
                      aria-expanded='false'
                      aria-controls={'priceModifierCollapse' + item?.id}
                      onClick={() => {
                        setPriceingTabel(!priceingTabel)
                        getPriceTemplateAsync(1)
                      }}
                    >
                      Price Modifier Rule
                    </button>
                  </h2>
                  <div
                    id={'priceModifierCollapse' + item?.id}
                    className='accordion-collapse collapse '
                    aria-labelledby='priceModifierHeading'
                    data-bs-parent='#priceModifierAccordion'
                  >
                    <div className='accordion-body'>
                    <div className='row d-flex'>
                        <div className="row pe-sm-0">
                            <div className='col-12 col-sm-6'>
                                <p className='fw-bold'>
                                    Add Price Modifier Rule for {item?.display_name}
                                </p>{' '}
                            </div>
                            <div className='col-12 col-sm-6 d-flex justify-content-end pe-0 mb-3'>
                                <button
                                    className='btn btn-primary btn-sm'
                                    onClick={() => {
                                    setUpdatePriceItem(null)
                                    setShowPriceTemplateModal(true)
                                    }}
                                >
                                    + Add new
                                </button>
                            </div>
                        </div>
                        {priceingTabel ? (
                          <div className='p-0 p-sm-2' >
                            {platformPricingTemplateLoading ? (
                              <div className='d-flex align-items-center justify-content-center'>
                                <h1>
                                  <Spinner animation='border' variant='primary' />
                                </h1>
                              </div>
                            ) : (
                              <>
                                {priceTemplatePagination.count === 0 ? (
                                  // Content to render when recipePagination.count is equal to 0
                                  <p className='mt-3'>No Price Modifier Rule found </p>
                                ) : (
                                  // Content to render when recipePagination.count is not equal to 0
                                  <div>
                                   <div className='card-body w-100 py-3'>
                                        {/* begin::Table container */}
                                        <div className='table-responsive '>
                                          {/* begin::Table */}
                                          <table className='table table-striped table-hover table-sm gs-0 gy-4'>
                                            {/* begin::Table head */}
                                            <thead>
                                              <tr className='fw-bold text-muted  border-bottom border-bottom-2'>
                                                <th className='min-w-150px ps-4'>Rule Name</th>
                                                <th className='min-w-100px text-center'>Actions</th>
                                              </tr>
                                            </thead>
                                            {/* end::Table head */}
                                            {/* begin::Table body */}
                                            <tbody>
                                              {priceTemplateList
                                                ? priceTemplateList.map((currentitem) => (
                                                    <>
                                                      <TemplateListItemcopy
                                                        key={currentitem.id}
                                                        name={currentitem.name}
                                                        // Pass update and delete handler functions here
                                                        onUpdate={() =>
                                                          handleUpdatePrice(currentitem)
                                                        }
                                                        onDelete={() =>
                                                     {     setSelectedTemplateDataForDelete(currentitem)
                                                      setShowAlertModalPrice(true)}
                                                          // handleDeletePrice(currentitem)
                                                        }
                                                      />
                                                      {/* <div className='border-bottom my-2 border-2'></div> */}
                                                    </>
                                                  ))
                                                : null}
                                            </tbody>
                                            {/* end::Table body */}
                                          </table>
                                          {/* end::Table */}
                                        </div>
                                        {/* end::Table container */}
                                     
                                    </div>
                                      <div className='d-flex flex-column'>
                                        <div className='border-bottom my-2 border-2'></div>
                                        <div className='d-flex flex-row justify-content-center mt-2'>
                                          <div className=' m-3'>
                                            <strong>
                                              Total {priceTemplatePagination.count} Records{' '}
                                            </strong>
                                          </div>
                                          <Button
                                            className='m-2'
                                            onClick={() => {
                                              setPriceTemplatePagination((pre) => {
                                                return {
                                                  ...pre,
                                                  page: priceTemplatePagination.page - 1,
                                                }
                                              })
                                              getPriceTemplateAsync(priceTemplatePagination.page - 1)
                                            }}
                                            disabled={priceTemplatePagination.previous == null}
                                          >
                                            -
                                          </Button>
                                          <Button
                                            className='m-2'
                                            onClick={() => {
                                              setPriceTemplatePagination((pre) => {
                                                return {
                                                  ...pre,
                                                  page: priceTemplatePagination.page + 1,
                                                }
                                              })
                                              getPriceTemplateAsync(priceTemplatePagination.page + 1)
                                            }}
                                            disabled={priceTemplatePagination.next == null}
                                          >
                                            +
                                          </Button>
                                        </div>
                                      </div>
                                  </div>
                                )}
                              </>
                            )}
  
                            {/* <div className='text-center '>
                            <button
                              className='btn btn-primary btn-sm mt-3'
                              onClick={() => {
                                setPriceingTabel(false)
                              }}
                            >
                              <FontAwesomeIcon icon={faArrowUp} />
                            </button>
                          </div> */}
                          </div>
                        ) : // <div
                        //   className='text-center '
                        //   onClick={() => {
                        //     getPriceTemplateAsync(1)
                        //   }}
                        // >
                        //   <button className='btn btn-primary btn-sm mt-3'>
                        //     <FontAwesomeIcon icon={faArrowDown} />
                        //   </button>
                        // </div>
                        null}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div className='accordion p-0 pt-2 p-sm-2' id='categoryMappingAccordion'>
                <div className='accordion-item shadow'>
                  <h2 className='accordion-header' id='categoryMappingHeading'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={'#categoryMappingCollapse' + item?.id}
                      aria-expanded='false'
                      aria-controls={'categoryMappingCollapse' + item?.id}
                      onClick={() => {
                        setShowCategoryData1(!showCategoryData1)
                        getCategoriesAsync()
                      }}
                    >
                      Category Mapping Rule
                    </button>
                  </h2>
                  <div
                    id={'categoryMappingCollapse' + item?.id}
                    className='accordion-collapse collapse '
                    aria-labelledby='categoryMappingHeading'
                    data-bs-parent='#categoryMappingAccordion'
                  >
                    <div className='accordion-body'>
                    <div className='row d-flex'>
                        <div className="row pe-sm-0">
                            <div className='col-12 col-sm-6'>
                            <p className='fw-bold '>
                            {' '}
                            Add Category Mapping Rule for {item?.display_name}
                          </p>{' '}
                            </div>
                            <div className='col-12 col-sm-6 d-flex justify-content-end pe-0 mb-3'>
                            <button
                            className='btn btn-primary btn-sm '
                            onClick={() => {
                              setShowCategoryTemplateModal(true)
                            }}
                          >
                            + Add new
                          </button>
                            </div>
                        </div>
  
                        {showCategoryData1 ? (
                          <div className='p-0 p-sm-2' >
                            {loadCategorieData ? (
                              <div className='d-flex align-items-center justify-content-center'>
                                <h1>
                                  <Spinner animation='border' variant='primary' />
                                </h1>
                              </div>
                            ) : (
                              <>
                                {paginationCategoryData.count === 0 ? (
                                  // Content to render when recipePagination.count is equal to 0
                                  <p className='mt-3'>No Category Mapping Rule found </p>
                                ) : (
                                  // Content to render when recipePagination.count is not equal to 0
                                  <div>
                                      <div className='card-body w-100 py-3'>
                                        {/* begin::Table container */}
                                        <div className='table-responsive '>
                                          {/* begin::Table */}
                                          <table className='table table-striped table-hover table-sm gs-0 gy-4'>
                                            {/* begin::Table head */}
                                            <thead>
                                              <tr className='fw-bold text-muted  border-bottom border-bottom-2'>
                                                <th className='min-w-150px ps-4'>Rule Name</th>
                                                <th className='min-w-100px text-center'>Actions</th>
                                              </tr>
                                            </thead>
                                            {/* end::Table head */}
                                            {/* begin::Table body */}
                                            <tbody>
                                              {platformCategories?.length
                                                ? platformCategories.map((templateData) => (
                                                    <>
                                                      <TemplateListItemcopy
                                                        key={templateData.id}
                                                        name={templateData.name}
                                                        // Pass update and delete handler functions here
                                                        onUpdate={() =>
                                                          handleUpdateCategory(templateData)
                                                        }
                                                        onDelete={() => {

                                                               setSelectedTemplateDataForDelete(templateData)
                                                            setShowAlertModalCategory(true)
                                                          // setloadCategorieData(true)
                                                         
                                                        }}
                                                      />
                                                      {/* <div className='border-bottom my-2 border-2'></div> */}
                                                    </>
                                                  ))
                                                : null}
                                            </tbody>
                                            {/* end::Table body */}
                                          </table>
                                          {/* end::Table */}
                                        </div>
                                        {/* end::Table container */}
                                      </div>
                                    
                                      <div className='d-flex flex-column'>
                                        <div className='border-bottom my-2 border-2'></div>
                                        <div className='d-flex flex-row justify-content-center mt-2'>
                                          <div className=' m-3'>
                                            <strong>
                                              Total {paginationCategoryData.count} Records{' '}
                                            </strong>
                                          </div>
                                          <Button
                                            className='m-2'
                                            onClick={() => {
                                              setPaginationCategoryData((pre) => {
                                                return {...pre, page: paginationCategoryData.page - 1}
                                              })
                                              getCategoriesAsync(paginationCategoryData.page - 1)
                                            }}
                                            disabled={paginationCategoryData.previous == null}
                                          >
                                            -
                                          </Button>
                                          <Button
                                            className='m-2'
                                            // onClick={() => {
                                            //   handlePaginationCategory(paginationCategoryData.page + 1)
                                            //   onSendPaginationCategory(paginationCategoryData)
                                            // }}
                                            // disabled={next == null}
                                            onClick={() => {
                                              setPaginationCategoryData((pre) => {
                                                return {...pre, page: paginationCategoryData.page + 1}
                                              })
                                              getCategoriesAsync(paginationCategoryData.page + 1)
                                            }}
                                            disabled={paginationCategoryData.next == null}
                                          >
                                            +
                                          </Button>
                                        </div>
                                      </div>
                                  </div>
                                )}
                              </>
                            )}
                            {/* 
                          <div className='text-center '>
                            <button
                              className='btn btn-primary btn-sm mt-3'
                              onClick={() => {
                                setShowCategoryData1(false)
                              }}
                            >
                              <FontAwesomeIcon icon={faArrowUp} />
                            </button>
                          </div> */}
                          </div>
                        ) : // <div
                        //   className='text-center'
                        //   // onClick={() => {
                        //   //   onClickShowCategoryTable(item?.id)
                        //   // }}
                        // >
                        //   <button
                        //     className='btn btn-primary btn-sm mt-3'
                        //     onClick={() => {
                        //       getCategoriesAsync()
                        //     }}
                        //   >
                        //     <FontAwesomeIcon icon={faArrowDown} />
                        //   </button>
                        // </div>
                        null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div className=' my-2 fs-16 text-center'>
                {/* <Button
                  // disabled={selectedCategoryIdForMapping == null}
                  onClick={() => callFieldMapping(item)}
                >
                 Load more mapping rules
                </Button> */}
                {showMoreMapping ? null : (
                  <button
                    type='button'
                    className='btn btn-sm fw-bold btn-primary'
                    onClick={() => callFieldMapping(item)}
                  >
                    {' '}
                    Click to see more Rules{' '}
                    {mappingLoading && <Spinner animation='border' size='sm' />}
                  </button>
                )}
              </div>

              
              {showMoreMapping && moreFieldMappingData.length > 0 && (
                <div>
                  {moreFieldMappingData.map((item) => (
                    <MoreMappingItem
                    item={item}
                    selectedPlatform={selectedPlatformId}
                    />
                  ))}
                </div>
              )}
            </div>
            {showAddTitleDescriptionModal ? 
            <AddNewTitleDescriptionModal
              isVisible={showAddTitleDescriptionModal}
              closeModal={handleCloseModal}
              selectedSecondaryPlatform={item}
              getLatestTitleDescriptionList={() => {
                setTitleDescriptionPagination({
                  page: 1,
                  count: 0,
                  limit: 5,
                  next: null,
                  previous: null,
                })
                getTitleDescriptionListAsync(1)
              }}
              updateItem={updatingTitleDescriptionData}
            /> : null }
            <AddNewPriceTemplateModal
              isVisible={showPriceTemplateModal}
              closeModal={() => {
                setShowPriceTemplateModal(false)
              }}
              selectedSecondaryPlatform={item}
              getLatestPriceTemplateList={() => {
                setPriceTemplatePagination({page: 1, count: 0, limit: 5, next: null, previous: null})
                getPriceTemplateAsync(1)
              }}
              updateItem={updatePriceItem}
            />
  
            <AddNewCategoryTemplateModal
              key={'addCatModal' + item.id}
              isVisible={showCategoryTemplateModal}
              closeModal={() => {
                setShowCategoryTemplateModal(false)
                setCategoryTemplateForUpdate(null)
              }}
              selectedSecondaryPlatform={item}
              categoryData={categoryTemplateForUpdate}
              getLatestCategoryTemplateList={() => getCategoriesAsync(1)}
            />
            {showMoreMappingTemplateModal && selectedMappingItem && (
              <AddNewMoreMappingTemplateModal
                isVisible={showMoreMappingTemplateModal}
                item={selectedMappingItem}
                selectedSecondaryPlatform={item}
                closeModal={() => setShowMoreMappingTemplateModal(false)}
                moreMappingData={updatingMoreMappingData}
                getLatestMoreMappingDataList={() => getMoreMappingDataAsync(1)}
              />
            )}
          </div>
        )}
        {activeTab.tab2 && (
          <div className='p-0 p-sm-2'>
            <div className='my-2'>
              <p className='h5 mt-2'>Manage your Product Listing Plans</p>
            </div>
            <div className='accordion p-0 pt-2 p-sm-2' id='productListingAccordion'>
              <div className='accordion-item shadow'>
                <h2 className='accordion-header' id='productListingHeading'>
                  <button
                    className='accordion-button'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={'#productListingCollapse' + item.id}
                    aria-expanded='false'
                    aria-controls={'productListingCollapse' + item.id}
                    onClick={() => {
                      setShowRecipeData(!showRecipeData)
                      getRecipesAsync(1)
                    }}
                  >
                    Add Plan for Product listing
                  </button>
                </h2>
                <div
                  id={'productListingCollapse' + item.id}
                  className='accordion-collapse collapse' // Removed 'show' class
                  aria-labelledby='productListingHeading'
                  data-bs-parent='#productListingAccordion'
                >
                  <div className='accordion-body'>
                    {/* Original content goes here */}
                    <div className='row  p-2 my-2 mt-3'>
                    <div className="row pe-sm-0">
                      <div className='col-12 col-sm-6'>
                        <p className='fw-bold'>
                          Add Plan for Product listing {item.display_name}{' '}
                        </p>
                      </div>
                      <div className='col-12 col-sm-6 d-flex justify-content-end pe-0 mb-3'>
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            onClickAddNewRecipeModal()
                          }}
                        >
                          + Add New Plan
                        </button>
                      </div>
                    </div>
                      {showRecipeData ? (
                        <div className='p-0 p-sm-2' >
                          {loadRecipeData ? (
                            <div className='d-flex align-items-center justify-content-center'>
                              <h1>
                                <Spinner animation='border' variant='primary' />
                              </h1>
                            </div>
                          ) : (
                            <>
                              {/* Content inside the accordion's collapsed section */}
                              {recipePagination.count === 0 ? (
                                // Content to render when recipePagination.count is equal to 0
                                <p className='mt-3'>No Listing Plan found </p>
                              ) : (
                                // Content to render when recipePagination.count is not equal to 0
                                <div className='justify-content-center d-flex mt-3'>
                                  <div className='justify-content-center card col-12'>
                                    <div className='card-body w-100 py-3'>
                                      {/* begin::Table container */}
                                      <div className='table-responsive '>
                                        {/* begin::Table */}
                                        <table className='table table-striped table-hover table-sm   gs-0 gy-4'>
                                          {/* begin::Table head */}
                                          <thead>
                                            <tr className='fw-bold text-muted  border-bottom border-bottom-2'>
                                              <th className='min-w-150px ps-4'>Plan Name</th>
                                              <th className='min-w-100px text-center'>Actions</th>
                                            </tr>
                                          </thead>
                                          {/* end::Table head */}
                                          {/* begin::Table body */}
                                          <tbody>
                                            {platformRecipes?.length
                                              ? platformRecipes.map((recipe) => (
                                                    <TemplateListItemcopy
                                                      key={recipe.id}
                                                      name={recipe.name}
                                                      // Pass update and delete handler functions here
                                                      onUpdate={() => {
                                                        setShowAddRecipeModal(true)
                                                        setUpdatingRecipeData(recipe)
                                                      }}
                                                      onDelete={() => {
                                                        // setLoadRecipeData(true)
                                                        setSelectedTemplateDataForDelete(recipe)
                                                        setShowAlertModalListing(true)
                                                      
                                                      }}
                                                    />
                                                ))
                                              : null}
                                          </tbody>
                                          {/* end::Table body */}
                                        </table>
                                        {/* end::Table */}
                                      </div>
                                      {/* end::Table container */}
                                    </div>
  
                                    <div className='d-flex flex-column'>
                                      <div className='border-bottom my-2 border-2 '></div>
                                      <div className='d-flex flex-row justify-content-center mt-2'>
                                        <div className=' m-3'>
                                          <strong>Total {recipePagination.count} Records </strong>
                                        </div>
                                        <Button
                                          className='m-2'
                                          onClick={() => {
                                            setRecipePagination((pre) => {
                                              return {...pre, page: recipePagination.page - 1}
                                            })
                                            getRecipesAsync(recipePagination.page - 1)
                                          }}
                                          disabled={recipePagination.previous == null}
                                        >
                                          -
                                        </Button>
                                        <Button
                                          className='m-2'
                                          onClick={() => {
                                            setRecipePagination((pre) => {
                                              return {...pre, page: recipePagination.page + 1}
                                            })
                                            getRecipesAsync(recipePagination.page + 1)
                                          }}
                                          disabled={recipePagination.next == null}
                                        >
                                          +
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {/* Toggle button */}
                          {/* <div className='text-center'>
                      <button
                        className='btn btn-primary btn-sm mt-3'
                        onClick={() => {
                          setShowRecipeData(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </button>
                    </div> */}
                        </div>
                      ) : (
                        // Button to expand the accordion
                        <div className='text-center'>
                          <button
                            className='btn btn-primary btn-sm mt-3'
                            onClick={() => {
                              getRecipesAsync()
                            }}
                          >
                            <FontAwesomeIcon icon={faArrowDown} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <AddNewRecipeModal
              key={'recipeModal' + item?.id}
              isVisible={showAddRecipeModal}
              closeModal={() => 
              {  
                setShowAddRecipeModal(false)
                setUpdatingRecipeData(null)
              }
              }
              selectedSecondaryPlatform={item}
              getLatestRecipesList={() => {
                setRecipePagination({page: 1, count: 0, limit: 5, next: null, previous: null})
                getRecipesAsync(1)
              }}
              recipeData={updatingRecipeData}
            />
          </div>
        )}


       <CommonAlertModal
           key={'title description'}
           title={` Are You Ready to Continue?`}
           subtitle='Click "Yes" to proceed.'
          //  successBtnTitle={ loggingIn ? 'Logging in...' : 'Confirm'}
          //  successDisabled={loggingIn}
           isVisible={showAlertModalTitle}
           onSuccess={()=>{
            try {
              if (selectedTemplateDataForDelete && selectedTemplateDataForDelete.id !== undefined) {
                dispatch(deleteTitleDescriptionTemplateAsync({platform_id: selectedTemplateDataForDelete?.id})).then(() => {
                  getTitleDescriptionListAsync(1)
                })
                toast.success('Deleted Successfully!', {
                  autoClose: 3000,
                })
              
              } else {
                console.error('template data  id is undefined.')
              }
            } catch (error) {
              console.error('Error deleting platform:', error)
            } finally {
              setShowAlertModalTitle(false)
            }
           }}
           onCancel={()=>{
         
            setSelectedTemplateDataForDelete(null)
            setShowAlertModalTitle(false)
           }}
           isTermsVisible={false}
          />
             <CommonAlertModal
           key={'price modifier'}
         title={` Are You Ready to Continue?`}
           subtitle='Click "Yes" to proceed.'
          //  successBtnTitle={ loggingIn ? 'Logging in...' : 'Confirm'}
          //  successDisabled={loggingIn}
           isVisible={showAlertModalPrice}
           onSuccess={()=>{
            dispatch(deletPlatformPriceAsync({platform_id: selectedTemplateDataForDelete?.id}))
            .then((response) => {
              successToast(response.payload?.success)
              getPriceTemplateAsync(1)
            })
            
            .catch((error) => {
              console.log('error', error)
            })
            setShowAlertModalPrice(false)

           }}
           onCancel={()=>{
         
         
            setShowAlertModalPrice(false)
           }}
           isTermsVisible={false}
          />
             <CommonAlertModal
           key={'category'}
            title={` Are You Ready to Continue?`}
           subtitle='Click "Yes" to proceed.'
          //  successBtnTitle={ loggingIn ? 'Logging in...' : 'Confirm'}
          //  successDisabled={loggingIn}
           isVisible={showAlertModalCategory}
           onSuccess={()=>{
            dispatch(
              deleteCategoryTemplateDataAsync({
                category_template: selectedTemplateDataForDelete.id,
              })
            )
              .then(() => {
                setloadCategorieData(false)
                getCategoriesAsync(1)
                toast.success('Deleted Successfully')
              })
              .catch(() => {
                setloadCategorieData(false)
              })
              setShowAlertModalCategory(false)
           }}
           onCancel={()=>{
         
            setSelectedTemplateDataForDelete(null)
            setShowAlertModalCategory(false)
           }}
           isTermsVisible={false}
          />
                 <CommonAlertModal
           key={'recipie listing'}
            title={` Are You Ready to Continue?`}
           subtitle='Click "Yes" to proceed.'
          //  successBtnTitle={ loggingIn ? 'Logging in...' : 'Confirm'}
          //  successDisabled={loggingIn}
           isVisible={showAlertModalListing}
           onSuccess={()=>{
            dispatch(
              deleteRecipeAsync({
                templateMappingId: selectedTemplateDataForDelete.id,
              })
            )
              .then(() => {
                setLoadRecipeData(false)
                getRecipesAsync(1)
              })
              .catch(() => {
                setLoadRecipeData(false)
              })
              setShowAlertModalListing(false)
           }}
           onCancel={()=>{
         
            setSelectedTemplateDataForDelete(null)
            setShowAlertModalListing(false)
           }}
           isTermsVisible={false}
          />
      </div>
    )
  }


  export default TemaplateComponent