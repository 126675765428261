import React, { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ConnectedPlatform } from 'src/interfaces/Platform.interface';
import { KTIcon, KTSVG } from 'src/marketConnect/helpers';
import { loggedInAdminAccessToken } from 'src/utils/constants';

type ConnectedPlatformStripe = {
  platform: ConnectedPlatform;
  onDelete: () => void;
};

const ConnectPlatformStrip1: FC<ConnectedPlatformStripe> = ({ platform, onDelete }) => {
  return (
    <div className='card shadow p-2 my-2'>
      <div className='d-sm-flex flex-md-row justify-content-between align-items-center p-8 border border-light-primary rounded position-relative'>
        <div className='card-title position-absolute top-0 start-0 p-2'>
          <h4 className="text-primary fs-4" >
            Primary Store
          </h4>
        </div>
        <div className='d-flex flex-row align-items-center  mt-12 ml-5'>
          <img
            src={platform.platform_info.logo_ref}
            alt='Channel Logo'
            className='me-2'
            style={{ maxWidth: '50px', height: '30px', objectFit: 'contain'}}
          />
          <h4 className='title mb-0'>{platform.display_name}</h4>
        </div>
         <div className='d-md-inline-flex d-flex  mt-sm-12 mt-4 justify-content-sm-start justify-content-between'>
          <span className='text-success fw-bold d-flex align-items-center justify-content-center align-self-center fs-5' style={{ height: '36px', lineHeight: '36px' }}>Connected</span>
          <div className='d-flex align-items-center'>
          { (['AIMS360','BIGCOMMERCE','SHEIN'].includes(platform.code) || (localStorage.getItem(loggedInAdminAccessToken))) &&
              <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props)=><Tooltip id="button-tooltip" {...props}>
              New Platform Setting
            </Tooltip>}
            >
            <Link to={`/platform-setting-new/${platform.platform_info.id}/${platform.id}`} className='mx-3 '>
              <KTSVG 
                path='media/icons/duotune/coding/cod001.svg'
                className='svg-icon-muted svg-icon-2hx'
              />
            </Link>
          </OverlayTrigger> 
          }
          {
            // !['AIMS360','BIGCOMMERCE'].includes(platform.code) && 
              <Link to={`/primary-platform-setting/${platform.id}`} className='mx-3'  >
                <KTSVG
                  path='media/icons/duotune/coding/cod009.svg'
                  className='svg-icon-muted svg-icon-2hx'
                />
              </Link>
          }
            <button className='btn btn-sm btn-danger' onClick={onDelete}>
              {/* <KTSVG
                path='media/icons/duotune/general/gen034.svg'
                className='svg-icon-muted svg-icon-2hx'
              /> */}
               <KTIcon iconName='trash' className=' fs-3' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectPlatformStrip1;
