import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faTimes, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./NotificationBell.css";
interface PlatformError {
  name: string;
  error_info: string;
}

interface NotificationBellProps {
  platformError?: PlatformError[];
}

const NotificationBell: React.FC<NotificationBellProps> = ({ platformError = [] }) => {
  const [isCardVisible, setIsCardVisible] = useState(false);

  const hasErrors = platformError.length > 0;

  return (
    <div className="position-relative d-inline-block">
      {/* Bell Icon */}
      <div onClick={() => setIsCardVisible(true)} className="notification-bell">
        <FontAwesomeIcon icon={faBell} className={`notification-bell ${hasErrors ? "ring-animation" : ""}`} />
      </div>

      {hasErrors && (
        <>
          {/* Notification Pill */}
          <div className="position-absolute translate-middle badge rounded-pill bg-danger notification-pill">
            Important Notifications
          </div>

          {/* Sliding Notification Card */}
          <div className={`position-fixed card shadow end-0 p-3 notification-card ${isCardVisible ? "visible" : ""}`}>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0 text-danger">Issues in your platform connections</h5>
              <FontAwesomeIcon icon={faTimes} className="text-danger" style={{ cursor: "pointer" }} onClick={() => setIsCardVisible(false)} />
            </div>
            <hr />

            {/* Error Cards */}
            {platformError.map((platform, index) => (
              <div key={index} className="error-card bg-light-danger mb-2 p-3">
                <FontAwesomeIcon icon={faExclamationCircle} size="lg" color="crimson" className="me-2" />
                <div>
                  <h6 className="mb-1 fw-bold">{platform.name}</h6>
                  <p className="mb-0 fs-6 error-text">{platform.error_info}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationBell;
