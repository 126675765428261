import React, { useEffect, useState } from "react"; 
import { Modal, Badge } from "react-bootstrap";
import useAppToast from "src/hooks/useAppToast";
import CommonConfirmationModal from "../../../utils/modals/ConfirmationModal";

const PayPalModal: React.FC<{ selectedPlan: any, email: string | undefined, onClose: () => void }> = ({ selectedPlan, email, onClose }) => {
  const { errorToast } = useAppToast();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [paypalRendered, setPaypalRendered] = useState(false);

  useEffect(() => {
    if (!showCancelModal && !paypalRendered) {
      setPaypalRendered(true); // Ensure PayPal buttons render only once

      setTimeout(() => {
        if ((window as any).paypal) {
          (window as any).paypal
            .Buttons({
              style: { layout: "vertical" },
              createSubscription: (data: any, actions: any) => {
                return actions.subscription.create({
                  plan_id: selectedPlan?.description,
                  application_context: {
                    user_action: "SUBSCRIBE_NOW",
                    brand_name: "COMMERCIUM",
                  },
                  custom_id: `${Date.now()}_uid_${selectedPlan?.description}`,
                  subscriber: {
                    email_address: email,
                  },
                });
              },
              onApprove: (data: any) => {  
                console.log("Subscription Approved", data);
                onClose();  
                window.location.href = `/listing-channels?subscription_id=${data.subscriptionID}&plan_id=${selectedPlan?.id}`;
              },
              onCancel: () => {
                setShowCancelModal(true);
                setPaypalRendered(false);
              },
            })
            .render("#paypal-buttons");
        }
      }, 100); // Small delay ensures modal is fully loaded before rendering buttons
    }
  }, [showCancelModal, selectedPlan, paypalRendered]);

  const handleConfirmCancel = () => {
    setShowCancelModal(true);
    errorToast("You have cancelled the subscription process.");
  };

  return (
    <>
      {!showCancelModal && (
        <Modal show onHide={onClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-secondary">Plan Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h5 className="text-lg">
              Plan  : <Badge>{selectedPlan?.plan_name}</Badge>
            </h5>
            <h5 className="text-lg">
              Price : <Badge bg="success">${selectedPlan?.price?.value} / {selectedPlan?.billing_frequency}</Badge>
            </h5>
            <div id="paypal-buttons" className="d-flex justify-content-center"></div>
          </Modal.Body>
        </Modal>
      )}

      <CommonConfirmationModal
        show={showCancelModal}
        title="Cancel Subscription?"
        message="Are you sure you want to cancel your subscription? This action cannot be undone."
        onConfirm={handleConfirmCancel}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};

export default PayPalModal;
