import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import InfoTooltip from './InfoTooltip'
import {
  FIELD_MAPPING_DATA,
  SECTION_WISE_SAVED_FIELD_MAPPING,
} from 'src/interfaces/Platform.interface'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRightArrowLeft, faDeleteLeft, faPlus} from '@fortawesome/free-solid-svg-icons'
import {useAppDispatch} from 'src/redux/storeHooks'
import {deleteFieldMappingAsync} from 'src/redux/actions/productActions'
import useAppToast from 'src/hooks/useAppToast'

const DependentMapping: FC<{
  savedMappings: any
  selectedPlatformId: number
  isMappingVisible: boolean
  setSavedMappings: any
  handleChange: (e: React.ChangeEvent<any>) => void
  field_mapping: any
  savedMapping: SECTION_WISE_SAVED_FIELD_MAPPING | null | undefined
  settingName: string
  settingId: number | null
  setFieldMappings: React.Dispatch<React.SetStateAction<FIELD_MAPPING_DATA[]>>
  fieldMappings: FIELD_MAPPING_DATA[]
  sourcePlatform?: string
  destinationPlatform?: string
  index: number
  primaryStoreOptions?: any
  primary_settings: boolean
}> = ({
  field_mapping,
  isMappingVisible,
  settingId,
  settingName,
  setFieldMappings,
  fieldMappings,
  destinationPlatform,
  sourcePlatform,
  index,
  savedMappings,
  setSavedMappings,
  savedMapping,
  primaryStoreOptions,
  primary_settings,
}) => {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const dispatch = useAppDispatch()
  const {successToast} = useAppToast()
  const [mapping, setMapping] = useState<any>([])
  const [isMappedWith, setIsMappedWith] = useState<any>(field_mapping?.map_with)
  const [sourceSide, setSourceSide] = useState<any>({})
  const [destinationSide, setDestinationSide] = useState<any>({})

  useEffect(() => {
    if (isMappedWith === null || isMappedWith === undefined) setIsMappedWith(primaryStoreOptions)

    if (isMappedWith) {
      if (primary_settings) {
        setSourceSide(field_mapping)
        setDestinationSide(isMappedWith)
      } else {
        setSourceSide(isMappedWith)
        setDestinationSide(field_mapping)
      }
    }
  }, [primaryStoreOptions, isMappedWith])

  useEffect(() => {
    if (savedMappings?.length > 0) {
      const saved_mapping = savedMappings?.filter((mapping: any) => {
        if (mapping?.setting_mapping_id) {
          return (
            mapping?.destination_field?.api_code === field_mapping.api_code &&
            mapping?.setting_id === settingId &&
            mapping.setting_mapping_id === field_mapping.setting_mapping_id
          );
        } else {
          return (
            mapping?.destination_field?.api_code === field_mapping.api_code &&
            mapping?.setting_id === settingId
          );
        }
      });      
      if (saved_mapping?.length > 0) {
        setMapping(saved_mapping)
      } else {
        setMapping([
          {
            id: null,
            setting_id: settingId,
            source_field: sourceSide,
            destination_field: destinationSide,
            setting_mapping_id: field_mapping?.setting_mapping_id,
            fieldmappingdata_set: [
              {
                id: null,
                destination_platform_data: '',
                destination_platform_data_str: '',
                source_platform_data: '',
                source_platform_data_str: '',
              },
            ],
          },
        ])
      }
    }
  }, [savedMappings])

  useEffect(() => {
    if (mapping?.length !== 0 && fieldMappings?.length === 0) {
      let field_mapping_data_set
      if (mapping[0].fieldmappingdata_set?.length > 0) {
        field_mapping_data_set = mapping[0].fieldmappingdata_set.map(
          ({source_platform_data, destination_platform_data, ...rest}) => ({
            ...rest,
            source_platform_data_id: source_platform_data?.id ?? null,
            destination_platform_data_id: destination_platform_data?.id ?? null,
          })
        )
      }
      if((primary_settings === true && field_mapping?.map_with != null) || (primary_settings === false && field_mapping?.map_with) || (primary_settings === false && primaryStoreOptions)){
      setFieldMappings((pre) => {
        return [
          ...pre,
          {
            field_mapping_id: mapping[0]?.id,
            groupCode: settingName,
            fieldCode: field_mapping.api_code,
            savedSettingId: settingId,
            savedMappingId: savedMapping?.id!,
            setting_mapping_id: mapping[0]?.setting_mapping_id,
            destinationFieldId: primary_settings ? field_mapping?.map_with?.id : field_mapping?.id,
            sourceFieldId: primary_settings ? field_mapping?.id : primaryStoreOptions?.id,
            fieldMappingDataSet: field_mapping_data_set || [],
          },
        ]
      })}
    }
  }, [mapping])

  const updateSavedMappings = (updatedMapping: any) => {
    setSavedMappings((prevMappings) => {
      const newMappings = [...prevMappings]
      updatedMapping.forEach((mapping) => {
        if (mapping.id === null) {
          const filtered = newMappings.filter(
            (item) =>
              item.setting_id === mapping.setting_id &&
              item.destination_field.api_code === mapping.destination_field.api_code
          )
          if (filtered.length === 0) {
            newMappings.push(mapping)
          } else {
            const index = newMappings.findIndex(
              (m) =>
                m.setting_id === mapping.setting_id &&
                m.destination_field.api_code === mapping.destination_field.api_code
            )
            if (index !== -1) {
              newMappings[index] = mapping
            }
          }
        } else {
          const index = newMappings.findIndex((m) => m.id === mapping.id)
          if (index !== -1) {
            newMappings[index] = mapping
          }
        }
      })

      return newMappings
    })
  }

  const validateField = (fieldName: string, value: any, isRequired: boolean) => {
    setErrors((prevErrors) => {
      const fieldKey = `${index}_${fieldName}`
      if (isRequired && (!value || (Array.isArray(value) && value.length === 0))) {
        return {...prevErrors, [fieldKey]: 'This field is required'}
      } else {
        const {[fieldKey]: _, ...newErrors} = prevErrors
        return newErrors
      }
    })
  }

  const formatDateString = (dateString: string) => {
    if (!dateString) return null
    const date = new Date(dateString.endsWith('Z') ? dateString.slice(0, -4) : dateString)
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
  }
  const handleInputChange = (
    value: any,
    field: string,
    dataField: string,
    isRequired: boolean,
    mapping_id,
    mappingId: number,
    targetIndex: number,
    mappingSide: any
  ) => {
    validateField(field, value, isRequired)
    const updatedMapping = mapping.map((item: any) => {
      let updatedFieldMappingDataSet
      if (mappingSide.input_type === 'SELECT') {
        updatedFieldMappingDataSet = item.fieldmappingdata_set.map((fm: any, index: number) =>
          index === targetIndex
            ? {
                ...fm,
                [field]: mappingSide.platformfielddata_set.find((p) => p.id === value) || null,
              }
            : fm
        )
      } else if (mappingSide.input_type === 'MULTISELECT') {
        updatedFieldMappingDataSet = value?.map((id) => ({
          destination_platform_data:
            mappingSide.platformfielddata_set.find((item: any) => item.id === Number(id)) || null,
          destination_platform_data_str: '',
          source_platform_data:
            mappingSide.platformfielddata_set.find((item: any) => item.id === Number(id)) || null,
          source_platform_data_str: '',
          id: null,
        }))
      } else {
        updatedFieldMappingDataSet = item.fieldmappingdata_set.map((fm: any) => ({
          ...fm,
          [dataField]: value || null,
        }))
      }
      return {...item, fieldmappingdata_set: updatedFieldMappingDataSet}
    })

    updateSavedMappings(updatedMapping)

    let updatedFieldMappings: any = [...fieldMappings]
    const selectedValues = Array.isArray(value) ? value : [value]
    selectedValues.forEach((val) => {
      let existingFieldMapping: any = updatedFieldMappings.find(
        (item) => 
          item.fieldCode === mappingSide.api_code && 
          item.groupCode === settingName && 
          (item.setting_mapping_id === null || item.setting_mapping_id === mappingSide?.setting_mapping_id)
      );      

      if (existingFieldMapping) {
        const isValueExists = existingFieldMapping.fieldMappingDataSet.some(
          (item) => item[dataField] === val
        )
        if (!isValueExists) {
          if (field_mapping.input_type === 'MULTISELECT') {
            existingFieldMapping.fieldMappingDataSet.push({
              id: mappingId,
              [dataField]: val,
            })
          } else {
            existingFieldMapping.fieldMappingDataSet[targetIndex] =
              existingFieldMapping.fieldMappingDataSet[targetIndex] || {}
            existingFieldMapping.fieldMappingDataSet[targetIndex] = {
              ...existingFieldMapping.fieldMappingDataSet[targetIndex],
              // id: mappingId,
              [dataField]: val,
            }
          }
        }
      } else {
        updatedFieldMappings.push({
          field_mapping_id: mapping[0]?.id,
          setting_mapping_id: mappingSide?.setting_mapping_id,
          groupCode: settingName,
          fieldCode: field_mapping.api_code,
          savedSettingId: settingId,
          savedMappingId: savedMapping?.id!,
          destinationFieldId: destinationSide?.id!,
          sourceFieldId: sourceSide?.id ?? null,
          isDefault: field_mapping?.isDefault,
          fieldMappingDataSet: [
            {
              [dataField]: val,
            },
          ],
        })
      }
    })
    setFieldMappings(updatedFieldMappings)
  }

  const renderInputField = (
    dataField: 'source_platform_data' | 'destination_platform_data',
    index: number,
    mappingSide: any
  ) => {
    const mappingSideOptions = (
      dataField === 'source_platform_data' ? sourceSide : destinationSide
    ) as {platformfielddata_set?: {id: string | number; api_name: string}[]}

    switch (mappingSide?.input_type) {
      case 'SELECT':
        return (
          <select
            className='form-select form-select-lg'
            value={mapping[0]?.fieldmappingdata_set[index][dataField]?.id || ''}
            required={mappingSide?.isRequired || false}
            onChange={(e) =>
              handleInputChange(
                Number(e.target.value),
                dataField,
                `${dataField}_id`,
                mappingSide?.isRequired,
                mapping[0]?.id,
                mapping[0]?.destination_field?.id,
                index,
                mappingSide
              )
            }
          >
            <option value=''>Select an option</option>
            {mappingSideOptions?.platformfielddata_set?.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.api_name}
              </option>
            ))}
          </select>
        )

      case 'MULTISELECT':
        return (
          <Select
            isMulti
            options={mappingSide?.platformfielddata_set?.map((item: any) => ({
              value: item.id,
              label: item.api_name,
            }))}
            value={mappingSide?.platformfielddata_set
              ?.filter((opt: any) =>
                mapping[0]?.fieldmappingdata_set?.some(
                  (item: any) => item?.[dataField]?.id === opt.id
                )
              )
              ?.map((item: any) => ({
                value: item.id,
                label: item.api_name,
              }))}
            onChange={(selectedOptions) => {
              const selectedValues = selectedOptions?.map((opt: any) => opt.value) || []
              handleInputChange(
                selectedValues,
                dataField,
                `${dataField}_id`,
                mappingSide?.isRequired,
                mapping[0]?.id,
                mapping[0]?.destination_field?.id,
                index,
                mappingSide
              )
            }}
          />
        )

      case 'BOOLEAN':
        return (
          <div className='form-check form-switch form-check-solid'>
            <input
              type='checkbox'
              className='form-check-input h-30px w-50px'
              checked={mapping[0]?.fieldmappingdata_set[0]?.[`${dataField}_str`] === 'true'}
              required={mappingSide?.isRequired || false}
              onChange={(e) =>
                handleInputChange(
                  e.target.checked ? 'true' : 'false',
                  dataField,
                  `${dataField}_str`,
                  mappingSide?.isRequired,
                  mapping[0]?.id,
                  mapping[0]?.destination_field?.id,
                  index,
                  mappingSide
                )
              }
            />
          </div>
        )

      case 'DATETIME':
      case 'INTEGER':
      case 'TEXTAREA':
      case 'TEXT':
        return (
          <input
            type={
              mappingSide.input_type === 'DATETIME'
                ? 'datetime-local'
                : mappingSide.input_type === 'INTEGER'
                ? 'number'
                : 'text'
            }
            placeholder={
              mappingSide.input_type === 'TEXTAREA' ? 'Add a description' : 'Enter value'
            }
            className={`form-control form-control-lg ${
              errors[`${index}_destination_platform_data_str`] ? 'border border-danger' : ''
            }`}
            value={
              mappingSide.input_type === 'DATETIME'
                ? formatDateString(mapping[0]?.fieldmappingdata_set[0]?.[`${dataField}_str`] ?? '')
                : mapping[index]?.fieldmappingdata_set[0]?.[`${dataField}_str`]
            }
            required={field_mapping?.isRequired || false}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                dataField,
                `${dataField}_str`,
                mappingSide?.isRequired,
                mapping[0]?.id,
                mapping[0]?.destination_field?.id,
                index,
                mappingSide
              )
            }
          />
        )

      default:
        return null
    }
  }

  const handleAddMapping = () => {
    const newMapping = {
      id: mapping[0].id,
      setting_id: mapping[0].setting_id,
      source_field: mapping[0]?.source_field,
      destination_field: mapping[0]?.destination_field,
      fieldmappingdata_set: [
        ...mapping[0]?.fieldmappingdata_set,
        {
          id: null,
          destination_platform_data: '',
          destination_platform_data_str: '',
          source_platform_data: '',
          source_platform_data_str: '',
        },
      ],
    }

    const updatedMapping = [newMapping]
    updateSavedMappings(updatedMapping)
  }

  const handleDeleteMapping = (targetIndex) => {
    let updatedMapping = mapping.map((mapItem) => ({
      ...mapItem,
      fieldmappingdata_set: mapItem.fieldmappingdata_set.filter(
        (_, index) => index !== targetIndex
      ),
    }))

    updateSavedMappings(updatedMapping)

    let updatedFieldMappings = [...fieldMappings]
    let existingFieldMapping: any = updatedFieldMappings.find(
      (item) => item.fieldCode === field_mapping.api_code && item.groupCode === settingName
    )
    if (existingFieldMapping) {
      existingFieldMapping?.fieldMappingDataSet?.splice(targetIndex, 1)
      setFieldMappings(updatedFieldMappings)
    }
  }
  console.log({fieldMappings, primary_settings, field_mapping, primaryStoreOptions, isMappedWith, settingName})
  return (
    <>
      {isMappedWith && (
        <div>
          <div className={clsx('row mb-2')}>
            <div className='mapping'>
              <div className='p-2'>
                <div>
                  <label className={`form-label ${field_mapping?.isRequired ? 'required' : ''}`}>
                    {sourceSide?.display_name} ({sourcePlatform}) to {destinationSide.display_name}{' '}
                    ({destinationPlatform}) mapping
                  </label>
                  {field_mapping?.description && (
                    <InfoTooltip message={field_mapping?.description} />
                  )}
                </div>
                <div className='border-bottom my-2 border-2 my-2'></div>
                <div key={'mapping' + index + mapping?.id + ''} className={`mb-sm-0 mb-2 p-2`}>
                  <div className='row'>
                    {mapping?.length &&
                      mapping[0]?.fieldmappingdata_set?.map((item, mappingFieldindex: number) => (
                        <>
                          <div className='col-12 col-sm-5'>
                            <div>
                              <label
                                htmlFor={`primaryStoreShippingMethod_${mappingFieldindex}`}
                                className={`form-check-label mb-1 ${
                                  field_mapping?.isRequired ? 'required' : ''
                                }`}
                              >
                                {sourceSide?.display_name} from {sourcePlatform}
                              </label>

                              {renderInputField(
                                'source_platform_data',
                                mappingFieldindex,
                                sourceSide
                              )}
                            </div>
                          </div>
                          <div className='col-12 col-sm-1 my-2 my-sm-0'>
                            <div className='d-flex justify-content-center align-items-center h-100'>
                              <FontAwesomeIcon icon={faArrowRightArrowLeft} className='mt-8' />
                            </div>
                          </div>
                          <div className='col-12 col-sm-5 mb-2'>
                            <div>
                              <label
                                htmlFor={`primaryStoreShippingMethod_${mappingFieldindex}`}
                                className={`form-check-label mb-1 ${
                                  field_mapping?.isRequired ? 'required' : ''
                                }`}
                              >
                                {destinationSide?.display_name} from {destinationPlatform}
                              </label>
                              {renderInputField(
                                'destination_platform_data',
                                mappingFieldindex,
                                destinationSide
                              )}
                            </div>
                          </div>
                          {mappingFieldindex != 0 ? (
                            <>
                              <div className='align-content-end col-12 col-sm-1'>
                                <div className='d-flex justify-content-end'>
                                  <button
                                    type='button'
                                    onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
                                      event.preventDefault()
                                      if (item?.id) {
                                        await dispatch(
                                          deleteFieldMappingAsync({mappingDataId: item?.id})
                                        ).then((response) => {
                                          successToast(response.payload.message)
                                        })
                                      }
                                      handleDeleteMapping(mappingFieldindex)
                                    }}
                                    className='btn btn-sm mt-2'
                                    style={{color: 'red'}}
                                  >
                                    <FontAwesomeIcon
                                      icon={faDeleteLeft}
                                      style={{fontSize: '20px'}}
                                    />
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : null}{' '}
                        </>
                      ))}
                    {
                      <div className='d-flex justify-content-end'>
                        <button
                          className='btn btn-primary btn-sm me-3'
                          type='button'
                          onClick={handleAddMapping}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>{' '}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DependentMapping
