import {FC, Fragment, useState} from 'react'
import InfoTooltip from './InfoTooltip'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useAppSelector } from 'src/redux/storeHooks'

const ShortCodeInput: FC<{
  inputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  label: string
  message: string
  inputWrapperClasses?: string
  containerClasses?: string
  labelClass?: string
}> = ({
  inputProps,
  label = '',
  message = '',
  inputWrapperClasses = '',
  containerClasses = 'col-12 col-sm-6 mt-4',
  labelClass = 'form-label',
}) => {
  const [isShortCodeVisible, setIsShortCodeVisible] = useState(false)
  const [shortCode, setShortCode] = useState(['[ORDER_NUMBER]'])
  const [copyMarkup, setCopyMarkup] = useState<boolean>(false)
  const [buttonString, setButtonString] = useState('')
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const { retrivedPlatformData} = useAppSelector(
    (state) => state.retrivePlatformData
  )
  
  // const [field] = useField({name:inputProps.name!, type:inputProps.type })


  const handleCopyMarkup = (data: string) => {
    setCopyMarkup(true)
    setButtonString(data)

    setTimeout(() => {
      setCopyMarkup(false)
      setButtonString('')
    }, 1000)
  }

  //   need to improve this currnetly this is only a fix
  const renderTooltip = (shortcode: string) => {
    let tooltipText = ''
    switch (shortcode) {
      case '[ORDER_ID]':
        tooltipText = ` ${mainStoreData?.code} Auto Generated`
        break
      case '[ORDER_NUMBER]':
        tooltipText = `Order Number from ${retrivedPlatformData?.code}`
        break
      default:
        tooltipText = ''
    }
    return <Tooltip id={`${shortcode}-tooltip`}>{tooltipText}</Tooltip>
  }

  return (
    <div className={containerClasses}>
      <label className={labelClass} htmlFor={inputProps.id}>
        {label} &nbsp;&nbsp;<InfoTooltip message={message} />
      </label>
      <div className={inputWrapperClasses}>
        <input {...inputProps} />
      </div>
      <div className='text-end w-md-100'>
        <button
          type='button'
          className='btn text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
          onClick={() => setIsShortCodeVisible(!isShortCodeVisible)} // Pass input identifier to handleShortCode}
        >
          <FontAwesomeIcon icon={faPlus} className='me-2' /> See Available Shortcodes
        </button>
        {isShortCodeVisible && (
          <div>
            {shortCode?.length &&
              shortCode.map((ele: string) => (
                <Fragment key={ele}>
                  <OverlayTrigger placement='bottom' overlay={renderTooltip(ele)}>
                    <CopyToClipboard text={ele}>
                      <Button
                        key={ele}
                        size='sm'
                        variant={
                          copyMarkup && buttonString === ele ? 'outline-success' : 'btn-outline-primary'
                        }
                        className={`px-3 m-1  border border-1 ${
                          copyMarkup && buttonString === ele ? 'border-success' : 'border-primary'
                        } rounded fs-sm-8 inline-block`}
                        onClick={() => handleCopyMarkup(ele)}
                      >
                        {copyMarkup && buttonString === ele ? (
                          <span className='fs-sm-8'>Copied !</span>
                        ) : (
                          ele
                        )}
                      </Button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                </Fragment>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ShortCodeInput
