import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'
import { TITLE_DESCRIPTION } from 'src/interfaces/Template.interface'

export const savePlatformTitleDescriptionTemplateAsync = createAsyncThunk(
  'platform/savePlatformTitleDescriptionTemplateAsync',
  async function (payload: TITLE_DESCRIPTION, toolkit) {
    const url = `/platform-title-description-template`

    return await AxiosClient('POST', url, payload, toolkit, [])
  }
)
export const getPlatformTitleDescriptionTemplateAsync = createAsyncThunk(
  'platform/getPlatformTitleDescriptionTemplateAsync',
  async function (
    params: {platform_id?: string; paginationdata: {page: number; limit: number}},
    toolkit
  ) {
    const url = `/platform-title-description-template?platform_id=${params.platform_id}`

    return await AxiosClient('GET', url, [], toolkit, params.paginationdata)
  }
)

export const updateTitleDescriptionTemplateAsync = createAsyncThunk(
  'platform/updateTitleDescriptionTemplateAsync',
  async function (params: {platform_id?: string; payload: any}, toolkit) {
    const url = `/platform-title-description-template/${params.platform_id}`

    return await AxiosClient('PUT', url, params.payload, toolkit, [])
  }
)

export const deleteTitleDescriptionTemplateAsync = createAsyncThunk(
  'platform/deleteTitleDescriptionTemplateAsync',
  async function (params: {platform_id?: string}, toolkit) {
    const url = `/platform-title-description-template/${params.platform_id}`

    return await AxiosClient('DELETE', url, [], toolkit, [])
  }
)
export const getShortCodeAsync = createAsyncThunk(
  'platform/getShortCodeAsync',
  async function (params: {shortcode_type?: string;search_string:string}, toolkit) {
    const url = `/shortcode?shortcode_type=${params.shortcode_type}&search_string=`

    return await AxiosClient('GET', url, [], toolkit, [])
  }
)

//get parent categories
export const getPlatformParentFieldDataCategoriesAsync = createAsyncThunk(
  'template&pricing/getPlatformParentFieldDataCategoriesAsync',
  async function (params: {platform_Id: number}, toolkit) {
    const url = `/api/platform-parent-field-data/${params.platform_Id}`
    return await AxiosClient('GET', url, [], toolkit, [])
  }
)

export const getPlatformFieldDataCategoriesAsync = createAsyncThunk(
  'template&pricing/getPlatformFieldDataCategoriesAsync',
  async function (params: {platform_field_ids: []}, toolkit) {
    const url = `/api/platform-parent-field-data?platform_field_id_list=${params.platform_field_ids}`
    return await AxiosClient('GET', url, [], toolkit, [])
  }
)


//get child categories
export const getPlatformChildFieldDataCategoriesAsync = createAsyncThunk(
  'template&pricing/getChildCategoriesAsync',
  async function (params: {platform_Id: number}, toolkit) {

 
    const url = `/api/get-child-field-data/${params.platform_Id}`

    return await AxiosClient('GET', url, [], toolkit, [])
  }
)

//in recipe side when user add rule for category then we need to get parent first after that we get the sub categories from that parent
export const  getPlatformParentFieldDataWhenPlatformIsCategorySelectedAsync = createAsyncThunk(
  'template&pricing/getPlatformParentFieldDataWhenPlatformIsCategorySelectedAsync',
  async function (params: {subCategory_id: any}, toolkit) {
    const url = `/api/get-field-data-tree/${params.subCategory_id}`
    return await AxiosClient('GET', url, [], toolkit, [])
  }
)


//get  getPlatformFieldDataAttributesAsync   when filedset=""

export const getPlatformFieldDataAttributesAsync = createAsyncThunk(
  'template&pricing/getPlatformFieldDataAttributesAsync',
  async function (params: {category_id: number}, toolkit) {

 
    const url = `/api/platform-field-data-attributes/${params.category_id}`


    return await AxiosClient('GET', url, [], toolkit, [])
  }
)


//post attributes 

export const postFieldDataAttributesAsync = createAsyncThunk(
  'template&pricing/postFieldDataAttributesAsync',
  async function (payload:any, toolkit) {
    const url = `/platform-category-template`
    return await AxiosClient('POST', url, payload, toolkit, [])
  }
)



//get  platform-category-template
export const getFieldDataAttributesAsync = createAsyncThunk(
  'template&pricing/getFieldDataAttributesAsync',
  async function (payload: {category_template: number}, toolkit) {
    const url = `/platform-category-template/${payload.category_template}`
    return await AxiosClient('GET', url, payload, toolkit, [])
  }
)

//get platform  platform-category-template data
export const getPlatformCategoryTemplateDataAsync = createAsyncThunk(
  'template&pricing/getPlatformCategoryTemplateDataAsync',
  async function (payload: {platform_id: number, paginationdata: {page: number; limit: number}}, toolkit) {
    const url = `/platform-category-template?platform_id=${payload.platform_id}`
    return await AxiosClient('GET', url, payload, toolkit, payload.paginationdata)
  }
)

//delete platform-category-template data 
export const deleteCategoryTemplateDataAsync = createAsyncThunk(
  'template&pricing/deleteCategoryTemplateDataAsync',
  async function (payload: {category_template: number}, toolkit) {
    const url = `/platform-category-template/${payload.category_template}`
    return await AxiosClient('DELETE', url, payload, toolkit, [])
  }
)


//update   platform-category-template data 
export const updateCategoryTemplateDataAsync = createAsyncThunk(
  'template&pricing/updateCategoryTemplateDataAsync',
  async function (payload: {category_template: number,payloadupdate:any}, toolkit) {
    const url = `/platform-category-template/${payload.category_template}`
    return await AxiosClient('PUT', url, payload.payloadupdate, toolkit, [])
  }
)

export const getSelectedCategoryTreeAsync = createAsyncThunk(
  'template&pricing/getSelectedCategoryTreeAsync',
  async function (params: {category_data_id:number}, toolkit) {
    return await AxiosClient('GET', `/api/get-field-data-tree/${params.category_data_id}`, [], toolkit, [])
  }
)

//get platform  platform-category-template data
export const getCategoryTemplateUsingTemplateIdAsync = createAsyncThunk(
  'template&pricing/getCategoryTemplateUsingTemplateIdAsync',
  async function (payload: {category_temp_id: number,}, toolkit) {
    return await AxiosClient('GET', `/platform-category-template/${payload.category_temp_id}`, payload, toolkit, [])
  }
)







export const createRecipeAsync = createAsyncThunk(
  'templateMapping/createRecipeAsync',
  async function(payload:any, toolkit){
    return await AxiosClient('POST',`/api/template-mapping`, payload, toolkit, [] )
  }
)

export const updateRecipeAsync = createAsyncThunk(
  'templateMapping/updateRecipeAsync',
  async function(params:{recipeId:string, payload:any}, toolkit){
    return await AxiosClient('PUT',`/api/template-mapping/${params.recipeId}`, params.payload, toolkit, [] )
  }
)

export const deleteRecipeAsync = createAsyncThunk(
  'templateMapping/deleteRecipeAsync',
  async function(params:{templateMappingId:string}, toolkit){
    return await AxiosClient('DELETE',`/api/template-mapping/${params.templateMappingId}`, [], toolkit, [] )
  }
)

export const deleteRecipeMappingItemAsync = createAsyncThunk(
  'templateMapping/deleteRecipeMappingItemAsync',
  async function(params:{templateMappingItemId:string}, toolkit){
    return await AxiosClient('DELETE',`/api/template-mapping-data/${params.templateMappingItemId}`, [], toolkit, [] )
  }
)

export const getRecipesAsyncUsingPlatformId = createAsyncThunk(
  'templateMapping/getRecipesAsyncUsingPlatformId',
  async function(params:{platformId:string, otherParams:{page: number; limit: number}}, toolkit){
    return await AxiosClient('GET',`/api/template-mapping-for-platform/${params.platformId}`, [], toolkit, params.otherParams  )
  }
)

export const getRecipesAsyncUsingRecipeId = createAsyncThunk(
  'templateMapping/getRecipesAsyncUsingRecipeId',
  async function(params:{recipeId:string, otherParams:any}, toolkit){
    return await AxiosClient('GET',`/api/template-mapping/${params.recipeId}`, [], toolkit, params.otherParams  )
  }
)

export const getCategoriesByPlatformIdAsync = createAsyncThunk(
  'templateMapping/getCategoriesByPlatformIdAsync',
  async function(params:{platformId:string,  paginationdata: {page: number; limit: number}}, toolkit){
    return await AxiosClient('GET',`/platform-category-template?platform_id=${params.platformId}`, [], toolkit, params.paginationdata  )
  }
)

//more mapping rules

export const createMoreMappingAsync = createAsyncThunk(
  'templateMapping/createMoreMappingAsync',
  async function(payload:any, toolkit){
    return await AxiosClient('POST',`/platform-field-template`, payload, toolkit, [] )
  }
)

// export const getMoreMappingAsync = createAsyncThunk(
//   'templateMapping/getMoreMappingAsync',
//   async function(params:{platforn_field_template_id:number ,paginationdata: {page: number; limit: number}}, toolkit){
//     return await AxiosClient('GET',`/platform-field-template/${params.platforn_field_template_id}`, [], toolkit, params.paginationdata )
//   }
// )
export const getMoreMappingAsync = createAsyncThunk(
  'templateMapping/getMoreMappingAsync',
  async function (params: { platform_id: number; field_id: number; paginationdata: { page: number; limit: number } }, toolkit) {
    const url = `/platform-field-template?platform_id=${params.platform_id}&field_id=${params.field_id}`;
    return await AxiosClient('GET', url, [], toolkit, params.paginationdata);
  }
)


export const updateMoreMappingAsync = createAsyncThunk(
  'templateMapping/updateMoreMappingAsync',
  async function(params:{platforn_field_template_id:string, payload:any}, toolkit){
    return await AxiosClient('PUT',`/platform-field-template/${params.platforn_field_template_id}`, params.payload, toolkit, [] )
  }
)

export const deleteMoreMappingAsync = createAsyncThunk(
  'templateMapping/deleteMoreMappingAsync',
  async function(params:{platforn_field_template_id:string}, toolkit){
    return await AxiosClient('DELETE',`/platform-field-template/${params.platforn_field_template_id}`, [], toolkit, [] )
  }
)

export const getPlatformFieldOtherTemplatesAsync = createAsyncThunk(
  'templateMapping/getPlatformFieldOtherTemplatesAsync',
  async function(params:{platform_id:string, field_id:string}, toolkit){
    return await AxiosClient('GET',`/platform-field-template`, [], toolkit, params  )
  }
)

export const deleteMoreMappingDataAsync = createAsyncThunk(
  'templateMapping/deleteMoreMappingDataAsync',
  async function(params:{platforn_field_template_data_id:string}, toolkit){
    return await AxiosClient('DELETE',`/platform-field-template-data/${params.platforn_field_template_data_id}`, [], toolkit, [] )
  }
)

//with search get attribute data
export const getPlatformCategoryAttributeDataAsync = createAsyncThunk(
  'templateMapping/getPlatformCategoryAttributeDataAsync',
  async function(params:{category_attribute_id:any, paramsData:any}, toolkit){
    return await AxiosClient('GET',`/api/platform-category-attribute-data/${params.category_attribute_id}`, [], toolkit, params.paramsData )
  }
)