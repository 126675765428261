import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import InfoTooltip from './InfoTooltip'
import {
  FIELD_MAPPING_DATA,
  SECTION_WISE_SAVED_FIELD_MAPPING,
} from 'src/interfaces/Platform.interface'

const IndependentMapping: FC<{
  savedMappings: any
  setSavedMappings: any
  sourceSide?: number
  destinationSide?: number
  selectedPlatformId: number
  isMappingVisible: boolean
  handleChange: (e: React.ChangeEvent<any>) => void
  field_mapping: any
  savedMapping: SECTION_WISE_SAVED_FIELD_MAPPING | null | undefined
  settingName: string
  settingId: number | null
  setFieldMappings: React.Dispatch<React.SetStateAction<FIELD_MAPPING_DATA[]>>
  fieldMappings: FIELD_MAPPING_DATA[]
  sourcePlatform?: string
  destinationPlatform?: string
  index: number
}> = ({
  field_mapping,
  isMappingVisible,
  savedMappings,
  setSavedMappings,
  settingId,
  settingName,
  setFieldMappings,
  fieldMappings,
  sourcePlatform,
  destinationPlatform,
  index,
  savedMapping,
}) => {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [mapping, setMapping] = useState<any>([])

  useEffect(() => {
    if (savedMappings?.length > 0) {
      const saved_mapping = savedMappings?.filter((mapping: any) => {
        if (mapping?.setting_mapping_id) {
          return (
            mapping?.destination_field?.api_code === field_mapping.api_code &&
            mapping?.setting_id === settingId &&
            mapping.setting_mapping_id === field_mapping.setting_mapping_id
          );
        } else {
          return (
            mapping?.destination_field?.api_code === field_mapping.api_code &&
            mapping?.setting_id === settingId
          );
        }
      });
      
      if (saved_mapping?.length > 0) {
        setMapping(saved_mapping)
      } else {
        setMapping([
          {
            id: null,
            setting_id: settingId,
            source_field: null,
            destination_field: field_mapping,
            setting_mapping_id: field_mapping?.setting_mapping_id || null,
            fieldmappingdata_set: [
              {
                id: null,
                destination_platform_data: '',
                destination_platform_data_str: '',
                source_platform_data: '',
                source_platform_data_str: '',
              },
            ],
          },
        ])
      }
    }
  }, [savedMappings])

  useEffect(()=>{
    if(mapping?.length !== 0 && fieldMappings?.length === 0){
      let field_mapping_data_set;
      if (mapping[0].fieldmappingdata_set?.length > 0) {
        field_mapping_data_set = mapping[0].fieldmappingdata_set.map(({ 
          source_platform_data, 
          destination_platform_data, 
          ...rest 
        }) => ({
          ...rest,
          source_platform_data_id: source_platform_data?.id ?? null,
          destination_platform_data_id: destination_platform_data?.id ?? null
        }));
      }
      setFieldMappings((pre) => {
       return [
         ...pre,
         {
          field_mapping_id: mapping[0]?.id,
           groupCode: settingName,
           fieldCode: field_mapping.api_code,
           savedSettingId: settingId,
           savedMappingId: savedMapping?.id!,
           destinationFieldId: field_mapping?.id!,
           setting_mapping_id: mapping[0]?.setting_mapping_id,
           sourceFieldId: null,
           fieldMappingDataSet: field_mapping_data_set || [],
         },
       ]
     })
     }
  },[mapping])


  const updateSavedMappings = (updatedMapping: any) => {
    setSavedMappings((prevMappings) => {
      const newMappings = [...prevMappings]
      updatedMapping.forEach((mapping) => {
        if (mapping.id === null) {
          const filtered = newMappings.filter(
            (item) =>
              item.setting_id === mapping.setting_id &&
              item.destination_field.api_code === mapping.destination_field.api_code
          )
          if (filtered.length === 0) {
            newMappings.push(mapping)
          } else {
            const index = newMappings.findIndex(
              (m) =>
                m.setting_id === mapping.setting_id &&
                m.destination_field.api_code === mapping.destination_field.api_code
            )
            if (index !== -1) {
              newMappings[index] = mapping
            }
          }
        } else {
          const index = newMappings.findIndex((m) => m.id === mapping.id)
          if (index !== -1) {
            newMappings[index] = mapping
          }
        }
      })

      return newMappings
    })
  }

  const validateField = (fieldName: string, value: any, isRequired: boolean) => {
    setErrors((prevErrors) => {
      const fieldKey = `${index}_${fieldName}`
      if (isRequired && (!value || (Array.isArray(value) && value.length === 0))) {
        return {...prevErrors, [fieldKey]: 'This field is required'}
      } else {
        const {[fieldKey]: _, ...newErrors} = prevErrors
        return newErrors
      }
    })
  }

  const formatDateString = (dateString: string) => {
    if (!dateString) return null
    const date = new Date(dateString.endsWith('Z') ? dateString.slice(0, -4) : dateString)
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
  }

  const handleInputChange = (
    value: any,
    field: string,
    dataField: string,
    isRequired: boolean,
    mapping_id,
    mappingId: number
  ) => {
    validateField(field, value, isRequired)

    const updatedMapping = mapping.map((item: any) => {
      let updatedFieldMappingDataSet
      if (field_mapping.input_type === 'SELECT') {
        updatedFieldMappingDataSet = item.fieldmappingdata_set.map((fm: any) => ({
          ...fm,
          [field]: field_mapping.platformfielddata_set.find((p) => p.id === value) || null,
        }))
      } else if (field_mapping.input_type === 'MULTISELECT') {
        updatedFieldMappingDataSet = value?.map((id) => ({
          destination_platform_data: field_mapping.platformfielddata_set.find(
            (item: any) => item.id === Number(id)
          ),
          destination_platform_data_str: '',
          source_platform_data: null,
          source_platform_data_str: '',
          id: null,
        }))
      } else {
        updatedFieldMappingDataSet = item.fieldmappingdata_set.map((fm: any) => ({
          ...fm,
          [dataField]: value || null,
        }))
      }

      return {...item, fieldmappingdata_set: updatedFieldMappingDataSet}
    })

    updateSavedMappings(updatedMapping)

    let updatedFieldMappings:any = [...fieldMappings]
    const selectedValues = Array.isArray(value) ? value : [value]
    let existingFieldMapping: any = updatedFieldMappings.find(
      (item) => 
        item.fieldCode === field_mapping.api_code && 
        item.groupCode === settingName && 
        (item.setting_mapping_id === null || item.setting_mapping_id === field_mapping?.setting_mapping_id)
    );
    if (selectedValues?.length === 0) {
      existingFieldMapping.fieldMappingDataSet = []
    } else {
      selectedValues.forEach((val) => {
        if (existingFieldMapping) {
          const isValueExists = existingFieldMapping.fieldMappingDataSet.some(
            (item) => item[dataField] === val
          )

          if (!isValueExists) {
            if (field_mapping.input_type === 'MULTISELECT') {
              existingFieldMapping.fieldMappingDataSet.push({
                id: mappingId,
                [dataField]: val,
              })
            } else {
              existingFieldMapping.fieldMappingDataSet[0]['id'] = mappingId
              existingFieldMapping.fieldMappingDataSet[0][dataField] = val
            }
          } else if (Array.isArray(value)) {
            existingFieldMapping.fieldMappingDataSet = [
              {
                id: mappingId,
                [dataField]: val,
              },
            ]
          }
        } else {
          updatedFieldMappings.push({
            field_mapping_id: mapping[0]?.id,
            groupCode: settingName,
            fieldCode: field_mapping.api_code,
            savedSettingId: settingId,
            savedMappingId: savedMapping?.id!,
            destinationFieldId: field_mapping?.id!,
            sourceFieldId: null,
            setting_mapping_id: field_mapping?.setting_mapping_id,
            fieldMappingDataSet: [
              {
                id: mappingId,
                [dataField]: val,
              },
            ],
          })
        }
      })
    }
    setFieldMappings(updatedFieldMappings)
  }

  const renderInputField = (dataField = 'destination_platform_data') => {
    switch (field_mapping?.input_type) {
      case 'SELECT':
        return (
          <select
            className='form-select form-select-lg'
            value={
              mapping[0]?.fieldmappingdata_set.find(
                (item: any) => item.destination_platform_data?.id
              )?.destination_platform_data?.id || ''
            }
            required={field_mapping?.isRequired || false}
            onChange={(e) =>
              handleInputChange(
                Number(e.target.value),
                'destination_platform_data',
                `${dataField}_id`,
                field_mapping?.isRequired,
                mapping[0]?.id,
                mapping[0]?.destination_field?.id
              )
            }
          >
            <option value=''>Select an option</option>
            {field_mapping?.platformfielddata_set?.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.api_name}
              </option>
            ))}
          </select>
        )

      case 'MULTISELECT':
        return (
          <Select
            isMulti
            options={field_mapping?.platformfielddata_set?.map((item: any) => ({
              value: item.id,
              label: item.api_name,
            }))}
            value={field_mapping?.platformfielddata_set
              ?.filter((opt: any) =>
                mapping[0]?.fieldmappingdata_set?.some(
                  (item: any) => item?.destination_platform_data?.id === opt.id
                )
              )
              ?.map((item: any) => ({
                value: item.id,
                label: item.api_name,
              }))}
            onChange={(selectedOptions) => {
              const selectedValues = selectedOptions?.map((opt: any) => opt.value) || []
              handleInputChange(
                selectedValues,
                'destination_platform_data',
                `${dataField}_id`,
                field_mapping?.isRequired,
                mapping[0]?.id,
                mapping[0]?.destination_field?.id
              )
            }}
          />
        )

      case 'BOOLEAN':
        return (
          <div className='form-check form-switch form-check-solid'>
            <input
              type='checkbox'
              className='form-check-input h-30px w-50px'
              checked={
                mapping[0]?.fieldmappingdata_set[0]?.destination_platform_data_str === 'true'
              }
              required={field_mapping?.isRequired || false}
              onChange={(e) =>
                handleInputChange(
                  e.target.checked ? 'true' : 'false',
                  'destination_platform_data_str',
                  `${dataField}_str`,
                  field_mapping?.isRequired,
                  mapping[0]?.id,
                  mapping[0]?.destination_field?.id
                )
              }
            />
          </div>
        )

      case 'DATETIME':
      case 'INTEGER':
      case 'TEXTAREA':
      case 'TEXT':
        return (
          <input
            type={
              field_mapping.input_type === 'DATETIME'
                ? 'datetime-local'
                : field_mapping.input_type === 'INTEGER'
                ? 'number'
                : 'text'
            }
            placeholder={
              field_mapping.input_type === 'TEXTAREA' ? 'Add a description' : 'Enter value'
            }
            className={`form-control form-control-lg ${
              errors[`${index}_destination_platform_data_str`] ? 'border border-danger' : ''
            }`}
            value={
              field_mapping.input_type === 'DATETIME'
                ? formatDateString(
                    mapping[0]?.fieldmappingdata_set[0]?.destination_platform_data_str ?? ''
                  )
                : mapping[0]?.fieldmappingdata_set[0]?.destination_platform_data_str
            }
            required={field_mapping?.isRequired || false}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                'destination_platform_data_str',
                `${dataField}_str`,
                field_mapping?.isRequired,
                mapping[0]?.id,
                mapping[0]?.destination_field?.id
              )
            }
          />
        )

      default:
        return null
    }
  }
  return (
    <div className={clsx('row mb-1', 'w-sm-50')}>
      <div className='mapping p-2'>
        <label className={`form-label ${field_mapping?.isRequired ? 'required' : ''}`}>
          Select {field_mapping?.display_name} from {sourcePlatform}
        </label>
        {field_mapping?.description && <InfoTooltip message={field_mapping?.description} />}
        {renderInputField('destination_platform_data')}
      </div>
    </div>
  )
}

export default IndependentMapping
