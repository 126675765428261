import React, { useEffect, useState } from "react";

interface ToggleSwitchProps {
  handleSwitch: (plan: "month" | "annual") => void;
  activePlanDetail: any
  isAnnual: boolean | null
  setIsAnnual: any
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ handleSwitch, activePlanDetail, isAnnual, setIsAnnual }) => {
 
  const [marginX, setMarginX] = useState("0");

  const toggleSwitch = () => {
    setIsAnnual((prev) => !prev);
    handleSwitch(isAnnual ? "month" : "annual");
  };

  const styles = {
    switchContainer: {
      width: "35px",
      height: "13px",
      backgroundColor: "lightgray",
      transition: "0.3s",
      position: "relative" as const,
      margin: "0 5px",
    },
    switchKnob: {
      width: "20px",
      height: "20px",
      transform: isAnnual ? "translateX(15px)" : "translateX(0px)",
      transition: "0.3s",
      position: "absolute" as const,
      left: 0,
      backgroundColor: "lightslategrey",
    },
  };

  // Function to detect zoom level and adjust margin
  const handleZoom = () => {
    const zoomLevel = window.innerWidth / window.outerWidth;
    if (zoomLevel > 1) {
      setMarginX("6rem"); 
    } else {
      setMarginX("0"); 
  }};

  useEffect(() => {
    window.addEventListener("resize", handleZoom);
    handleZoom(); 
    return () => window.removeEventListener("resize", handleZoom);
  }, []);

  return (
    <div className="d-flex justify-content-end align-items-center mt-4 mb-3" style={{ marginLeft: marginX, marginRight: marginX }}>
      <div className="me-3">
        <div className="d-flex justify-content-center align-items-center">
          <span className="fs-6 fw-bold" style={{color: 'darkblue'}}>Monthly</span>

          {/* Toggle Switch */}
          <div
            className="d-inline-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={toggleSwitch}
          >
            <div className="d-flex align-items-center p-1 rounded-pill" style={styles.switchContainer}>
              <div className="rounded-circle shadow" style={styles.switchKnob}></div>
            </div>
          </div>

          <span className="fs-6 fw-bold" style={{color: 'darkblue', marginBottom: '1px'}}>Annual</span>
        </div>

        {/* Badge for Annual Offer */}
        <div className="badge badge-warning text-dark px-2 mb-0 py-1 mt-1 fs-13 d-flex justify-content-end">
          Get 2 months free on Annual
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
