import {faArrowRightArrowLeft, faDeleteLeft, faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Field, Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {PlatformSettingDataTypesValues} from 'src/app/_interfaces/setting.interface'
import useAppToast from 'src/hooks/useAppToast'
import {
  FIELD_MAPPING_DATA,
  FIELD_MAPPING_TYPE,
  PLATFORM_FIELD,
  SECTION_WISE_SAVED_FIELD_MAPPING,
  ShippingMethodMapping,
  platformfielddata_set,
} from 'src/interfaces/Platform.interface'
import {deleteFieldMappingAsync, getPlatformFieldsAsync} from 'src/redux/actions/productActions'
import {getPlatformParentFieldDataCategoriesAsync} from 'src/redux/actions/templateAndPricingActions'
import {useAppDispatch} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import {getSavedFieldMappingsDataAsyncByMappingId} from 'src/redux/actions/platformSettingActions'
import clsx from 'clsx'
import Select from 'react-select'
import InfoTooltip from './InfoTooltip'

const MappingInput: FC<{
  sourceSide?: number
  destinationSide?: number
  selectedPlatformId: number
  isMappingVisible: boolean
  handleChange: (e: React.ChangeEvent<any>) => void
  field_mapping: FIELD_MAPPING_TYPE
  savedMapping: SECTION_WISE_SAVED_FIELD_MAPPING | null | undefined
  settingName: string
  settingId: number | null
  setFieldMappings: React.Dispatch<React.SetStateAction<FIELD_MAPPING_DATA[]>>
  fieldMappings: FIELD_MAPPING_DATA[]
  sourcePlatform?: string
  destinationPlatform?: string
}> = ({
  field_mapping,
  isMappingVisible,
  sourceSide,
  destinationSide,
  savedMapping,
  settingId,
  settingName,
  setFieldMappings,
  fieldMappings,
  sourcePlatform,
  destinationPlatform,
}) => {
  const [platformFields, setPlatformFields] = useState<{
    sourcePlatformField: null | PLATFORM_FIELD
    destinationPlatformField: null | PLATFORM_FIELD
  }>({
    sourcePlatformField: null,
    destinationPlatformField: null,
  })
  const [shipmentMappingFieldData, setShipmentMappingFieldData] = useState<{
    destinationPlatformFieldData: platformfielddata_set[] | []
    sourcePlatformFieldData: platformfielddata_set[] | []
  }>({sourcePlatformFieldData: [], destinationPlatformFieldData: []})

  const [shippingMethodMappings, setShippingMethodMappings] = useState<ShippingMethodMapping[]>([
    {
      id: null,
      source_platform_data_id: '',
      destination_platform_data_id: '',
      destination_platform_data_str: '',
      source_platform_data_str: '',
    },
  ])

  const [loadingMappingFields, setLoadingMappingFields] = useState(true)
  const [loadingShippingMappingFieldData, setLoadingShippingMappingFieldData] = useState(true)
  const dispatch = useAppDispatch()
  const {successToast} = useAppToast()
  const [errors, setErrors] = useState<Record<string, string>>({});

  //for getting saved values
  useEffect(() => {
    let mappings: ShippingMethodMapping[] | [] = []
    if (savedMapping && platformFields.destinationPlatformField?.id) {
      setLoadingMappingFields(true)
      dispatch(getSavedFieldMappingsDataAsyncByMappingId({mappingId: savedMapping.id}))
        .then((response) => {
          if (response?.payload?.fieldmappingdata_set?.length) {
            mappings = response?.payload.fieldmappingdata_set.map((item) => ({
              id: item?.id,
              destination_platform_data_id: item?.destination_platform_data?.id
                ? item?.destination_platform_data?.id + ''
                : '',
              source_platform_data_id: item?.source_platform_data
                ? item?.source_platform_data?.id + ''
                : null,
              destination_platform_data_str: item.destination_platform_data_str ?? '',
              source_platform_data_str: item.source_platform_data_str ?? '',
            }))
          }
        })
        .catch((error) => {
          console.log('unable to get field mappings', error)
        })
        .finally(() => {

          if (
            platformFields.destinationPlatformField?.id &&
            platformFields.sourcePlatformField?.id
          ) {
            if(!field_mapping?.isDefault){
              setShippingMethodMappings(mappings)
              setFieldMappings((pre) => {
                return [
                  ...pre,
                  {
                    groupCode: settingName,
                    fieldCode: field_mapping.field_code,
                    savedSettingId: settingId,
                    savedMappingId: savedMapping?.id!,
                    destinationFieldId: platformFields.destinationPlatformField?.id!,
                    sourceFieldId: platformFields.sourcePlatformField?.id!,
                    fieldMappingDataSet: mappings,
                  },
                ]
              })
            }
          }
          //below case for default mapping
          if (
            platformFields.destinationPlatformField?.id &&
            platformFields.sourcePlatformField === null
          ) {
            if(field_mapping.isDefault){
              if(platformFields.destinationPlatformField?.input_type === 'MULTISELECT'){
                const destinationIds = mappings
                .map((item:any) => item.destination_platform_data_id)
                .filter(Boolean) 

                const ids = mappings
                .map((item:any) => item.id)
                .filter(Boolean) 
                const mergedData = {
                id: ids ?? null, 
                destination_platform_data_id: destinationIds,
                source_platform_data_id: mappings[0]?.source_platform_data_id ?? null,
                destination_platform_data_str: mappings[0]?.destination_platform_data_str ?? "",
                source_platform_data_str: mappings[0]?.source_platform_data_str ?? "",
              };

              setShippingMethodMappings([mergedData])
                setFieldMappings((pre) => {
                  return [
                    ...pre,
                    {
                      groupCode: settingName,
                      fieldCode: field_mapping.field_code,
                      savedSettingId: settingId,
                      savedMappingId: savedMapping?.id!,
                      destinationFieldId: platformFields.destinationPlatformField?.id!,
                      sourceFieldId: null,
                      fieldMappingDataSet: mappings,
                    },
                  ]
                })
              }else {
                setShippingMethodMappings([mappings[0]])
                setFieldMappings((pre) => {
                  return [
                    ...pre,
                    {
                      groupCode: settingName,
                      fieldCode: field_mapping.field_code,
                      savedSettingId: settingId,
                      savedMappingId: savedMapping?.id!,
                      destinationFieldId: platformFields.destinationPlatformField?.id!,
                      sourceFieldId: null,
                      fieldMappingDataSet: [mappings[0]],
                    },
                  ]
                })
              }
            }
          }

          setLoadingMappingFields(false)
        })
    } else {
      setLoadingMappingFields(false)
    }
  }, [destinationSide, platformFields, savedMapping])

  //for getting platform fields and fields data
  useEffect(() => {
    if (!destinationSide) return; 
  
    const fetchPlatformFields = async () => {
      setLoadingShippingMappingFieldData(true);
  
      const promises:any = [];
  
      // Fetch source side data only if field_mapping is true
      if (!field_mapping?.isDefault && sourceSide) {
        const sourcePromise = dispatch(
          getPlatformFieldsAsync({
            platformId: Number(sourceSide),
            otherParams: { field_code: field_mapping?.field_code },
          })
        ).then(async (response) => {
          if (response.payload?.length) {
            const sourceField = response.payload[0];
  
            setPlatformFields((prev) => ({
              ...prev,
              sourcePlatformField: field_mapping.isDefault ? null : sourceField,
            }));
  
            // Fetch parent field data
            const parentDataResponse = await dispatch(
              getPlatformParentFieldDataCategoriesAsync({ platform_Id: sourceField.id })
            );
  
            setShipmentMappingFieldData((prev) => ({
              ...prev,
              sourcePlatformFieldData: parentDataResponse.payload,
            }));
          }
        });
  
        promises.push(sourcePromise);
      }
  
      // Fetch destination side data
      const destinationPromise = dispatch(
        getPlatformFieldsAsync({
          platformId: Number(destinationSide),
          otherParams: { field_code: field_mapping?.field_code },
        })
      ).then(async (response) => {
        if (response.payload?.length) {
          const destinationField = response.payload[0];
  
          setPlatformFields((prev) => ({
            ...prev,
            destinationPlatformField: destinationField,
          }));
  
          // Dispatch getPlatformParentFieldDataCategoriesAsync only for "select" or "multiselect"
          if (["SELECT", "MULTISELECT"].includes(destinationField.input_type)) {
            const parentDataResponse = await dispatch(
              getPlatformParentFieldDataCategoriesAsync({ platform_Id: destinationField.id })
            );
            setShipmentMappingFieldData((prev) => ({
              ...prev,
              destinationPlatformFieldData: parentDataResponse.payload,
            }));
          }
        }
      });
  
      promises.push(destinationPromise);
  
      // Wait for all promises to complete
      Promise.all(promises).finally(() => {
        setLoadingShippingMappingFieldData(false);
      });
    };
  
    fetchPlatformFields();
  }, [isMappingVisible, field_mapping]);
  
  function formatDateString(dateString:string) {
    // Parse the date string to a Date object
    let date;
    if(dateString){
      if (dateString.endsWith("Z")) {
          let zremovedval = dateString.slice(0, dateString.length-4)
          // If the date string ends with 'Z', it's in UTC
          date = new Date(zremovedval);
      } else {
          // Otherwise, it's in the local time zone
          date = new Date(dateString);
      }
      // Adjust the date to the local time zone if needed
      const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
  
      // Format the local date for the datetime-local input
      return localDate.toISOString().slice(0, 16);
    }else{
      return null
    }

  }

  const validateField = (index: number, fieldName: string, value: any, isRequired: boolean) => {
    const fieldKey = `${index}_${fieldName}`;
  
    if (isRequired) {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldKey]: 'This field is required',
        }));
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[fieldKey];
          return newErrors;
        });
      }
    }
  };
  
  

  if (loadingShippingMappingFieldData || loadingMappingFields) return <Loader />
  return (
    <div
      className={clsx(
        'row mb-2',
        platformFields.sourcePlatformField === null || field_mapping.isDefault ? 'w-sm-50' : ''
      )}
    >
      <div className='mapping'>
        <div className='p-2 '>
          <div>
          <label className={`form-label ${field_mapping?.isRequired ? 'required' : ''}`}>
          {field_mapping?.isDefault
                ? `Select ${field_mapping?.display_name} from ${destinationPlatform} `
                : `${platformFields.sourcePlatformField?.display_name} (${sourcePlatform}) to  ${platformFields.destinationPlatformField?.display_name} (${destinationPlatform}) mapping`}
            </label> {field_mapping?.isDefault && platformFields.destinationPlatformField?.description && <InfoTooltip message={platformFields.destinationPlatformField.description}/>}
          </div>
          {!field_mapping?.isDefault && <div className='border-bottom my-2 border-2 my-2'></div>}
          {shippingMethodMappings?.length
            ? shippingMethodMappings?.map((mapping, index) => {
                return (
                  <div key={'mapping' + index + mapping?.id + ''} className={`mb-sm-0 mb-2 ${field_mapping.isDefault === false ? 'p-2' : '' }`}>
                    <div className='row'>
                      {platformFields.sourcePlatformField && !field_mapping?.isDefault ? (
                        platformFields.sourcePlatformField?.input_type === 'SELECT' ? (
                          <div className='col-12 col-sm-5'>
                            <div>
                              <label
                                htmlFor={`primaryStoreShippingMethod_${index}`}
                                className={`form-check-label ${field_mapping?.isRequired ? 'required' : ''}`}
                                >
                                {field_mapping?.display_name} from {sourcePlatform}
                              </label>
                              <select
                                className={`form-select form-select-lg mt-2`}
                                id='primaryStoreShippingMethod'
                                name={`mappings[${index}][source_platform_data_id]`}
                                value={mapping?.source_platform_data_id ?? ''}
                                onChange={(ev) => {
                                  validateField(
                                    index,
                                    'source_platform_data_id',
                                    ev.target.value,
                                    field_mapping?.isRequired
                                  )
                                  let val: ShippingMethodMapping = {
                                    id: mapping.id ? mapping.id : null,
                                    source_platform_data_id: ev.target.value,
                                    destination_platform_data_str: '',
                                    source_platform_data_str: '',
                                  }

                                  if (
                                    platformFields.destinationPlatformField?.input_type === 'SELECT'
                                  ) {
                                    if (!field_mapping.isDefault) {
                                      val.destination_platform_data_id =
                                        mapping.destination_platform_data_id
                                    } else {
                                      val.destination_platform_data_id = null
                                    }
                                  } else {
                                    if (!field_mapping?.isDefault) {
                                      val.destination_platform_data_str =
                                        mapping.destination_platform_data_str
                                    }
                                  }

                                  let shadowArrayMapping = [...shippingMethodMappings]
                                  shadowArrayMapping[index] = val

                                  let shadowFieldMappings = [...fieldMappings]
                                  let existingFieldMapping = shadowFieldMappings.find(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )
                                  let existingFieldMappingIndex = shadowFieldMappings.findIndex(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )

                                  if (existingFieldMapping) {
                                    existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                    shadowFieldMappings[existingFieldMappingIndex] =
                                      existingFieldMapping
                                    setFieldMappings(shadowFieldMappings)
                                  } else {
                                    setFieldMappings((pre) => {
                                      return [
                                        ...pre,
                                        {
                                          groupCode: settingName,
                                          fieldCode: field_mapping.field_code,
                                          savedSettingId: settingId,
                                          savedMappingId: savedMapping?.id!,
                                          destinationFieldId:
                                            platformFields.destinationPlatformField?.id!,
                                          sourceFieldId:
                                            platformFields.sourcePlatformField?.id ?? null,
                                          fieldMappingDataSet: shadowArrayMapping,
                                        },
                                      ]
                                    })
                                  }

                                  setShippingMethodMappings([...shadowArrayMapping])
                                }}
                                required={field_mapping?.isRequired || false}
                              >
                                <option value=''>Open this select menu</option>
                                {shipmentMappingFieldData?.sourcePlatformFieldData?.length &&
                                  shipmentMappingFieldData.sourcePlatformFieldData.map(
                                    (item: platformfielddata_set) => {
                                      return (
                                        <option
                                          key={'sourceFieldData' + settingName + item.id}
                                          value={item.id}
                                        >
                                          {item.api_name}
                                        </option>
                                      )
                                    }
                                  )}
                              </select>
                              {errors[`${index}_source_platform_data_id`] && (
                                <small className='text-danger'>
                                  {errors[`${index}_source_platform_data_id`]}
                                </small>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className='col-12 col-sm-5'>
                            <div className=''>
                              <label
                                htmlFor={`primaryStoreShippingMethod_${index}`}
                                className={`form-check-label ${field_mapping?.isRequired ? 'required' : ''}`}
                              >
                                {field_mapping?.display_name} from {sourcePlatform}
                              </label>
                              <input
                                type='text'
                                placeholder='Add a method'
                                className={`form-control form-control-lg mt-2 ${errors[`${index}_source_platform_data_str`] ? 'border border-danger': ''}`}
                                name={`mappings[${index}][source_platform_data_str]`}
                                value={mapping.source_platform_data_str}
                                required={field_mapping?.isRequired || false}
                                onChange={(ev) => {
                                  validateField(
                                    index,
                                    'source_platform_data_str',
                                    ev.target.value,
                                    field_mapping?.isRequired
                                  )
                                  let val: ShippingMethodMapping = {
                                    id: mapping.id ? mapping.id : null,
                                    source_platform_data_str: ev.target.value,
                                  }

                                  if (
                                    platformFields.destinationPlatformField?.input_type === 'SELECT'
                                  ) {
                                    if (!field_mapping.isDefault) {
                                      val.destination_platform_data_id =
                                        mapping.destination_platform_data_id
                                    }
                                  } else {
                                    if (!field_mapping?.isDefault) {
                                      val.destination_platform_data_str =
                                        mapping.destination_platform_data_str
                                    }
                                  }

                                  let shadowArrayMapping = [...shippingMethodMappings]
                                  shadowArrayMapping[index] = val

                                  let shadowFieldMappings = [...fieldMappings]
                                  let existingFieldMapping = shadowFieldMappings.find(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )
                                  let existingFieldMappingIndex = shadowFieldMappings.findIndex(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )

                                  if (existingFieldMapping) {
                                    existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                    shadowFieldMappings[existingFieldMappingIndex] =
                                      existingFieldMapping
                                    setFieldMappings(shadowFieldMappings)
                                  } else {
                                    setFieldMappings((pre) => {
                                      return [
                                        ...pre,
                                        {
                                          groupCode: settingName,
                                          fieldCode: field_mapping.field_code,
                                          savedSettingId: settingId,
                                          savedMappingId: savedMapping?.id!,
                                          destinationFieldId:
                                            platformFields.destinationPlatformField?.id!,
                                          sourceFieldId:
                                            platformFields.sourcePlatformField?.id ?? null,
                                          fieldMappingDataSet: shadowArrayMapping,
                                        },
                                      ]
                                    })
                                  }

                                  setShippingMethodMappings([...shadowArrayMapping])
                                }}
                              />
                               {errors[`${index}_source_platform_data_str`] && (
                                <small className='text-danger'>
                                  {errors[`${index}_source_platform_data_str`]}
                                </small>
                              )}
                            </div>
                          </div>
                        )
                      ) : null}
                      {platformFields.sourcePlatformField && !field_mapping?.isDefault ? (
                        <div className='col-12 col-sm-1 my-2 my-sm-0'>
                          <div className='d-flex justify-content-center align-items-center h-100'>
                            <FontAwesomeIcon icon={faArrowRightArrowLeft} className='mt-8' />
                          </div>
                        </div>
                      ) : null}
                      {platformFields.destinationPlatformField ? (
                        platformFields.destinationPlatformField?.input_type === 'SELECT' ? (
                          <div
                            className={clsx(
                              'col-12',
                              platformFields.sourcePlatformField === null || field_mapping.isDefault
                                ? ''
                                : 'col-sm-5'
                            )}
                          >
                            <div className=''>
                              {!field_mapping.isDefault && <label
                                htmlFor={`secondaryStoreShippingMethod_${index}`}
                                className={`form-check-label ${field_mapping?.isRequired ? 'required' : ''}`}
                              >
                                {field_mapping?.display_name} from {destinationPlatform}
                              </label>}
                              <select
                                className={`form-select form-select-lg ${field_mapping.isDefault === true ? '' : 'mt-2'}`}
                                id='secondaryStoreShippingMethod'
                                name={`mappings[${index}][destination_platform_data_id]`}
                                value={mapping?.destination_platform_data_id ?? ''}
                                required={field_mapping?.isRequired || false}
                                onChange={(ev) => {
                                  const value = ev.target.value
                                  validateField(
                                    index,
                                    'destination_platform_data_id',
                                    value,
                                    field_mapping?.isRequired
                                  )
                                  let val: ShippingMethodMapping = {
                                    id: mapping?.id ? mapping?.id : null,
                                    destination_platform_data_id: ev.target.value,
                                    destination_platform_data_str: '',
                                    source_platform_data_str: '',
                                  }

                                  //if mapping having isDefault true that means it is single mapping field
                                  if (platformFields.sourcePlatformField?.input_type === 'SELECT') {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_id = mapping.source_platform_data_id
                                    } else {
                                      val.source_platform_data_id = null
                                    }
                                  } else {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_str =
                                        mapping.source_platform_data_str
                                    }
                                  }
                                  let shadowArrayMapping = [...shippingMethodMappings]
                                  shadowArrayMapping[index] = val

                                  let shadowFieldMappings = [...fieldMappings]
                                  let existingFieldMapping = shadowFieldMappings.find(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )
                                  let existingFieldMappingIndex = shadowFieldMappings.findIndex(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )

                                  if (existingFieldMapping) {
                                    existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                    shadowFieldMappings[existingFieldMappingIndex] =
                                      existingFieldMapping
                                    setFieldMappings(shadowFieldMappings)
                                  } else {
                                    setFieldMappings((pre) => {
                                      return [
                                        ...pre,
                                        {
                                          groupCode: settingName,
                                          fieldCode: field_mapping.field_code,
                                          savedSettingId: settingId,
                                          savedMappingId: savedMapping?.id!,
                                          destinationFieldId:
                                            platformFields.destinationPlatformField?.id!,
                                          sourceFieldId:
                                            platformFields.sourcePlatformField?.id ?? null,
                                          fieldMappingDataSet: shadowArrayMapping,
                                        },
                                      ]
                                    })
                                  }
                                  setShippingMethodMappings([...shadowArrayMapping])
                                }}
                              >
                                <option value=''>Open this select menu</option>
                                {shipmentMappingFieldData?.destinationPlatformFieldData?.length &&
                                  shipmentMappingFieldData.destinationPlatformFieldData?.map(
                                    (item: platformfielddata_set) => {
                                      return (
                                        <option
                                          key={'destinationFieldData' + settingName + item.id}
                                          value={item.id}
                                        >
                                          {item.api_name}
                                        </option>
                                      )
                                    }
                                  )}
                              </select>
                              {errors[`${index}_destination_platform_data_id`] && (
                                <small className='text-danger'>
                                  {errors[`${index}_destination_platform_data_id`]}
                                </small>
                              )}
                            </div>
                          </div>
                        ) : platformFields.destinationPlatformField?.input_type ===
                          'MULTISELECT' ? (
                          <div
                            className={clsx(
                              'col-12',
                              platformFields.sourcePlatformField === null || field_mapping.isDefault
                                ? ''
                                : 'col-sm-5'
                            )}
                          >
                            <div className=''>
                              {/* <label
                                htmlFor={`secondaryStoreShippingMethod_${index}`}
                                className='form-check-label'
                              >
                                {field_mapping?.display_name} from {destinationPlatform}
                              </label> */}
                              <Select
                                id='secondaryStoreShippingMethod'
                                name={`mappings[${index}][destination_platform_data_id]`}
                                required={field_mapping?.isRequired || false}
                                options={shipmentMappingFieldData?.destinationPlatformFieldData?.map(
                                  (item: platformfielddata_set) => ({
                                    value: item.id,
                                    label: item.api_name,
                                  })
                                )}
                                value={shipmentMappingFieldData?.destinationPlatformFieldData
                                  ?.filter((opt: platformfielddata_set) =>
                                    Array.isArray(mapping?.destination_platform_data_id)
                                      ? mapping?.destination_platform_data_id.includes(
                                          opt.id?.toString()
                                        )
                                      : mapping?.destination_platform_data_id === opt.id.toString()
                                  )
                                  ?.map((item: platformfielddata_set) => ({
                                    value: item.id,
                                    label: item.api_name,
                                  }))}
                                onChange={(selectedOptions) => {
                                  const selectedValues = Array.isArray(selectedOptions)
                                    ? selectedOptions.map((opt) => opt.value.toString())
                                    : null

                                  validateField(
                                    index,
                                    'destination_platform_data_id',
                                    selectedValues,
                                    field_mapping?.isRequired
                                  )
                                const selectedOptionIds = selectedValues && selectedValues.map((value) => {
                                    const selectedOptionIndex = mapping?.destination_platform_data_id.indexOf(value);
                                    return selectedOptionIndex !== -1 ? mapping?.id[selectedOptionIndex] : null;
                                });
                            
                                  let val: ShippingMethodMapping = {
                                    id: selectedOptionIds || null,
                                    destination_platform_data_id: selectedValues,
                                    destination_platform_data_str: '',
                                    source_platform_data_str: '',
                                  }

                                  if (platformFields.sourcePlatformField?.input_type === 'SELECT') {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_id = mapping.source_platform_data_id
                                    } else {
                                      val.source_platform_data_id = null
                                    }
                                  } else {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_str =
                                        mapping.source_platform_data_str
                                    }
                                  }
                                  let shadowArrayMapping = [...shippingMethodMappings]
                                  // shadowArrayMapping[index] = val
                                  
                                  if (val?.destination_platform_data_id?.length > 0) {
                                    const {
                                      destination_platform_data_id,
                                      source_platform_data_id,
                                      id,
                                      destination_platform_data_str,
                                    } = val
                                    val.destination_platform_data_id.map(
                                      (value: any, i: number) => {
                                        shadowArrayMapping[i] = {
                                          destination_platform_data_id: value.toString(),
                                          source_platform_data_id,
                                          id: Number(val.id[i]),
                                          destination_platform_data_str,
                                        }
                                      }
                                    )
                                  }
                                  let shadowFieldMappings = [...fieldMappings]
                                  let existingFieldMapping = shadowFieldMappings.find(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )
                                  let existingFieldMappingIndex = shadowFieldMappings.findIndex(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )

                                  if (existingFieldMapping) {
                                    existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                    shadowFieldMappings[existingFieldMappingIndex] =
                                      existingFieldMapping
                                    setFieldMappings(shadowFieldMappings)
                                  } else {
                                    setFieldMappings((pre) => [
                                      ...pre,
                                      {
                                        groupCode: settingName,
                                        fieldCode: field_mapping.field_code,
                                        savedSettingId: settingId,
                                        savedMappingId: savedMapping?.id!,
                                        destinationFieldId:
                                          platformFields.destinationPlatformField?.id!,
                                        sourceFieldId:
                                          platformFields.sourcePlatformField?.id ?? null,
                                        fieldMappingDataSet: shadowArrayMapping,
                                      },
                                    ])
                                  }

                                  setShippingMethodMappings([val])
                                }}
                                isMulti
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    borderColor: errors[`${index}_destination_platform_data_id`] ? "crimson" : base.borderColor,
                                    boxShadow: state.isFocused
                                      ? errors[`${index}_destination_platform_data_id`] 
                                        ? "0 0 0 1px crimson" 
                                        : "0 0 0 1px #2684FF"  // Default focus color
                                      : "none",
                                    "&:hover": {
                                      borderColor: errors[`${index}_destination_platform_data_id`] ? "crimson" : base.borderColor,
                                    },
                                  }),
                                }}
                                
                                classNamePrefix='react-select'
                              />
                               {errors[`${index}_destination_platform_data_id`] && (
                                <small className='text-danger'>
                                  {errors[`${index}_destination_platform_data_id`]}
                                </small>
                              )}
                            </div>
                          </div>
                        ) : platformFields.destinationPlatformField?.input_type === 'BOOLEAN' ? (
                          <div
                            className={clsx(
                              'col-12',
                              platformFields.sourcePlatformField === null || field_mapping.isDefault
                                ? ''
                                : 'col-sm-5'
                            )}
                          >
                            <div className=''>
                              {/* <label
                                htmlFor={`secondaryStoreShippingMethod_${index}`}
                                className='form-check-label'
                              >
                                {field_mapping?.display_name} from {destinationPlatform}
                              </label> */}
                              <div className='form-check form-switch form-check-solid'>
                                <input
                                  type='checkbox'
                                  className='form-check-input h-30px w-50px'
                                  name={`mappings[${index}][destination_platform_data_str]`}
                                  checked={mapping.destination_platform_data_str === 'true'}
                                  required={field_mapping?.isRequired || false}
                                  onChange={(ev) => {
                                    validateField(
                                      index,
                                      'destination_platform_data_str',
                                      ev.target.value,
                                      field_mapping?.isRequired
                                    )

                                    let val: ShippingMethodMapping = {
                                      id: mapping.id ? mapping.id : null,
                                      destination_platform_data_str: ev.target.checked
                                        ? 'true'
                                        : 'false',
                                    }

                                    if (
                                      platformFields.sourcePlatformField?.input_type === 'SELECT'
                                    ) {
                                      if (!field_mapping?.isDefault) {
                                        val.source_platform_data_id =
                                          mapping.source_platform_data_id
                                      }
                                    } else {
                                      if (!field_mapping?.isDefault) {
                                        val.source_platform_data_str =
                                          mapping.source_platform_data_str
                                      }
                                    }

                                    let shadowArrayMapping = [...shippingMethodMappings]
                                    shadowArrayMapping[index] = val

                                    let shadowFieldMappings = [...fieldMappings]
                                    let existingFieldMapping = shadowFieldMappings.find(
                                      (item) =>
                                        item.fieldCode === field_mapping.field_code &&
                                        item.groupCode === settingName
                                    )
                                    let existingFieldMappingIndex = shadowFieldMappings.findIndex(
                                      (item) =>
                                        item.fieldCode === field_mapping.field_code &&
                                        item.groupCode === settingName
                                    )

                                    if (existingFieldMapping) {
                                      existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                      shadowFieldMappings[existingFieldMappingIndex] =
                                        existingFieldMapping
                                      setFieldMappings(shadowFieldMappings)
                                    } else {
                                      setFieldMappings((pre) => {
                                        return [
                                          ...pre,
                                          {
                                            groupCode: settingName,
                                            fieldCode: field_mapping.field_code,
                                            savedSettingId: settingId,
                                            savedMappingId: savedMapping?.id!,
                                            destinationFieldId:
                                              platformFields.destinationPlatformField?.id!,
                                            sourceFieldId:
                                              platformFields.sourcePlatformField?.id ?? null,
                                            fieldMappingDataSet: shadowArrayMapping,
                                          },
                                        ]
                                      })
                                    }
                                    setShippingMethodMappings([...shadowArrayMapping])
                                  }}
                                />
                                 {errors[`${index}_destination_platform_data_id`] && (
                                <small className='text-danger'>
                                  {errors[`${index}_destination_platform_data_id`]}
                                </small>
                              )}
                              </div>
                            </div>
                          </div>
                        ) : platformFields.destinationPlatformField?.input_type === 'DATETIME' ? (
                          <div
                            className={clsx(
                              'col-12',
                              platformFields.sourcePlatformField === null || field_mapping.isDefault
                                ? ''
                                : 'col-sm-5'
                            )}
                          >
                            <div className=''>
                              {/* <label
                                htmlFor={`secondaryStoreShippingMethod_${index}`}
                                className='form-check-label'
                              >
                                {field_mapping?.display_name} from {destinationPlatform}
                              </label> */}
                              <input
                                type='datetime-local'
                                placeholder='Add a method'
                                className={`form-control form-control-lg ${errors[`${index}_destination_platform_data_str`] ? 'border border-danger': ''}`}
                                name={`mappings[${index}][destination_platform_data_str]`}
                                value={
                                  formatDateString(mapping?.destination_platform_data_str ?? '') ||
                                  ''
                                }
                                required={field_mapping?.isRequired || false}
                                onChange={(ev) => {
                                  validateField(
                                    index,
                                    'destination_platform_data_str',
                                    ev.target.value,
                                    field_mapping?.isRequired
                                  )

                                  let val: ShippingMethodMapping = {
                                    id: mapping.id ? mapping.id : null,
                                    destination_platform_data_str: ev.target.value,
                                  }

                                  if (platformFields.sourcePlatformField?.input_type === 'SELECT') {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_id = mapping.source_platform_data_id
                                    }
                                  } else {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_str =
                                        mapping.source_platform_data_str
                                    }
                                  }

                                  let shadowArrayMapping = [...shippingMethodMappings]
                                  shadowArrayMapping[index] = val

                                  let shadowFieldMappings = [...fieldMappings]
                                  let existingFieldMapping = shadowFieldMappings.find(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )
                                  let existingFieldMappingIndex = shadowFieldMappings.findIndex(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )

                                  if (existingFieldMapping) {
                                    existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                    shadowFieldMappings[existingFieldMappingIndex] =
                                      existingFieldMapping
                                    setFieldMappings(shadowFieldMappings)
                                  } else {
                                    setFieldMappings((pre) => {
                                      return [
                                        ...pre,
                                        {
                                          groupCode: settingName,
                                          fieldCode: field_mapping.field_code,
                                          savedSettingId: settingId,
                                          savedMappingId: savedMapping?.id!,
                                          destinationFieldId:
                                            platformFields.destinationPlatformField?.id!,
                                          sourceFieldId:
                                            platformFields.sourcePlatformField?.id ?? null,
                                          fieldMappingDataSet: shadowArrayMapping,
                                        },
                                      ]
                                    })
                                  }
                                  setShippingMethodMappings([...shadowArrayMapping])
                                }}
                              />
                               {errors[`${index}_destination_platform_data_str`] && (
                                <small className='text-danger'>
                                  {errors[`${index}_destination_platform_data_str`]}
                                </small>
                              )}
                            </div>
                          </div>
                        ) : platformFields.destinationPlatformField?.input_type === 'INTEGER' ? (
                          <div
                            className={clsx(
                              'col-12',
                              platformFields.sourcePlatformField === null || field_mapping.isDefault
                                ? ''
                                : 'col-sm-5'
                            )}
                          >
                            <div className=''>
                              {/* <label
                                htmlFor={`secondaryStoreShippingMethod_${index}`}
                                className='form-check-label'
                              >
                                {field_mapping?.display_name} from {destinationPlatform}
                              </label> */}
                              <input
                                type='number'
                                placeholder='Add amount'
                                className={`form-control form-control-lg ${errors[`${index}_destination_platform_data_str`] ? 'border border-danger': ''}`}
                                name={`mappings[${index}][destination_platform_data_str]`}
                                value={mapping.destination_platform_data_str}
                                required={field_mapping?.isRequired || false}
                                onChange={(ev) => {
                                  validateField(
                                    index,
                                    'destination_platform_data_str',
                                    ev.target.value,
                                    field_mapping?.isRequired
                                  )

                                  let val: ShippingMethodMapping = {
                                    id: mapping.id ? mapping.id : null,
                                    destination_platform_data_str: ev.target.value,
                                  }

                                  if (platformFields.sourcePlatformField?.input_type === 'SELECT') {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_id = mapping.source_platform_data_id
                                    }
                                  } else {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_str =
                                        mapping.source_platform_data_str
                                    }
                                  }

                                  let shadowArrayMapping = [...shippingMethodMappings]
                                  shadowArrayMapping[index] = val

                                  let shadowFieldMappings = [...fieldMappings]
                                  let existingFieldMapping = shadowFieldMappings.find(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )
                                  let existingFieldMappingIndex = shadowFieldMappings.findIndex(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )

                                  if (existingFieldMapping) {
                                    existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                    shadowFieldMappings[existingFieldMappingIndex] =
                                      existingFieldMapping
                                    setFieldMappings(shadowFieldMappings)
                                  } else {
                                    setFieldMappings((pre) => {
                                      return [
                                        ...pre,
                                        {
                                          groupCode: settingName,
                                          fieldCode: field_mapping.field_code,
                                          savedSettingId: settingId,
                                          savedMappingId: savedMapping?.id!,
                                          destinationFieldId:
                                            platformFields.destinationPlatformField?.id!,
                                          sourceFieldId:
                                            platformFields.sourcePlatformField?.id ?? null,
                                          fieldMappingDataSet: shadowArrayMapping,
                                        },
                                      ]
                                    })
                                  }
                                  setShippingMethodMappings([...shadowArrayMapping])
                                }}
                              />
                               {errors[`${index}_destination_platform_data_str`] && (
                                <small className='text-danger'>
                                  {errors[`${index}_destination_platform_data_str`]}
                                </small>
                              )}
                            </div>
                          </div>
                        ) : platformFields.destinationPlatformField?.input_type === 'TEXTAREA' ? (
                          <div
                            className={clsx(
                              'col-12',
                              platformFields.sourcePlatformField === null || field_mapping.isDefault
                                ? ''
                                : 'col-sm-5'
                            )}
                          >
                            <div className=''>
                              {/* <label
                                htmlFor={`secondaryStoreShippingMethod_${index}`}
                                className='form-check-label'
                              >
                                {field_mapping?.display_name} from {destinationPlatform}
                              </label> */}
                              <textarea
                                placeholder='Add a description'
                                className={`form-control form-control-lg ${errors[`${index}_destination_platform_data_str`] ? 'border border-danger': ''}`}
                                rows={2}
                                name={`mappings[${index}][destination_platform_data_str]`}
                                value={mapping.destination_platform_data_str}
                                required={field_mapping?.isRequired || false}
                                onChange={(ev) => {
                                  validateField(
                                    index,
                                    'destination_platform_data_str',
                                    ev.target.value,
                                    field_mapping?.isRequired
                                  )

                                  let val: ShippingMethodMapping = {
                                    id: mapping.id ? mapping.id : null,
                                    destination_platform_data_str: ev.target.value,
                                  }

                                  if (platformFields.sourcePlatformField?.input_type === 'SELECT') {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_id = mapping.source_platform_data_id
                                    }
                                  } else {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_str =
                                        mapping.source_platform_data_str
                                    }
                                  }

                                  let shadowArrayMapping = [...shippingMethodMappings]
                                  shadowArrayMapping[index] = val

                                  let shadowFieldMappings = [...fieldMappings]
                                  let existingFieldMapping = shadowFieldMappings.find(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )
                                  let existingFieldMappingIndex = shadowFieldMappings.findIndex(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )

                                  if (existingFieldMapping) {
                                    existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                    shadowFieldMappings[existingFieldMappingIndex] =
                                      existingFieldMapping
                                    setFieldMappings(shadowFieldMappings)
                                  } else {
                                    setFieldMappings((pre) => {
                                      return [
                                        ...pre,
                                        {
                                          groupCode: settingName,
                                          fieldCode: field_mapping.field_code,
                                          savedSettingId: settingId,
                                          savedMappingId: savedMapping?.id!,
                                          destinationFieldId:
                                            platformFields.destinationPlatformField?.id!,
                                          sourceFieldId:
                                            platformFields.sourcePlatformField?.id ?? null,
                                          fieldMappingDataSet: shadowArrayMapping,
                                        },
                                      ]
                                    })
                                  }
                                  setShippingMethodMappings([...shadowArrayMapping])
                                }}
                              />
                               {errors[`${index}_destination_platform_data_str`] && (
                                <small className='text-danger'>
                                  {errors[`${index}_destination_platform_data_str`]}
                                </small>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div
                            className={clsx(
                              'col-12',
                              platformFields.sourcePlatformField === null || field_mapping.isDefault
                                ? ''
                                : 'col-sm-5'
                            )}
                          >
                            <div className=''>
                              {!field_mapping.isDefault && <label
                                htmlFor={`secondaryStoreShippingMethod_${index}`}
                                className={`form-check-label ${field_mapping?.isRequired ? 'required' : ''}`}
                              >
                                {field_mapping?.display_name} from {destinationPlatform}
                              </label>}
                              <input
                                type='text'
                                placeholder='Add a method'
                                className={`form-control form-control-lg ${errors[`${index}_destination_platform_data_str`] ? 'border border-danger': ''} ${field_mapping.isDefault === true ? '' : 'mt-2'}`}
                                name={`mappings[${index}][destination_platform_data_str]`}
                                value={mapping.destination_platform_data_str}
                                required={field_mapping?.isRequired || false}
                                onChange={(ev) => {
                                  validateField(
                                    index,
                                    'destination_platform_data_str',
                                    ev.target.value,
                                    field_mapping?.isRequired
                                  )

                                  let val: ShippingMethodMapping = {
                                    id: mapping.id ? mapping.id : null,
                                    destination_platform_data_str: ev.target.value,
                                  }

                                  if (platformFields.sourcePlatformField?.input_type === 'SELECT') {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_id = mapping.source_platform_data_id
                                    }
                                  } else {
                                    if (!field_mapping?.isDefault) {
                                      val.source_platform_data_str =
                                        mapping.source_platform_data_str
                                    }
                                  }

                                  let shadowArrayMapping = [...shippingMethodMappings]
                                  shadowArrayMapping[index] = val

                                  let shadowFieldMappings = [...fieldMappings]
                                  let existingFieldMapping = shadowFieldMappings.find(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )
                                  let existingFieldMappingIndex = shadowFieldMappings.findIndex(
                                    (item) =>
                                      item.fieldCode === field_mapping.field_code &&
                                      item.groupCode === settingName
                                  )

                                  if (existingFieldMapping) {
                                    existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                    shadowFieldMappings[existingFieldMappingIndex] =
                                      existingFieldMapping
                                    setFieldMappings(shadowFieldMappings)
                                  } else {
                                    setFieldMappings((pre) => {
                                      return [
                                        ...pre,
                                        {
                                          groupCode: settingName,
                                          fieldCode: field_mapping.field_code,
                                          savedSettingId: settingId,
                                          savedMappingId: savedMapping?.id!,
                                          destinationFieldId:
                                            platformFields.destinationPlatformField?.id!,
                                          sourceFieldId:
                                            platformFields.sourcePlatformField?.id ?? null,
                                          fieldMappingDataSet: shadowArrayMapping,
                                        },
                                      ]
                                    })
                                  }
                                  setShippingMethodMappings([...shadowArrayMapping])
                                }}
                              />
                              {errors[`${index}_destination_platform_data_str`] && (
                                <small className='text-danger'>
                                  {errors[`${index}_destination_platform_data_str`]}
                                </small>
                              )}
                            </div>
                          </div>
                        )
                      ) : null}
                      {index !== 0 && shippingMethodMappings?.length > 1 ? (
                        <div className='align-content-end col-12 col-sm-1'>
                          <div className='d-flex justify-content-end'>
                            <button
                              type='button'
                              onClick={() => {
                                let copyMppings = [...shippingMethodMappings]
                                if (mapping.id) {
                                  dispatch(
                                    deleteFieldMappingAsync({mappingDataId: mapping.id})
                                  ).then((response) => {
                                    successToast(response.payload.message)

                                    if (savedMapping) {
                                      setLoadingMappingFields(true)
                                      dispatch(
                                        getSavedFieldMappingsDataAsyncByMappingId({
                                          mappingId: savedMapping.id,
                                        })
                                      ).then((response) => {
                                        if (response?.payload?.fieldmappingdata_set?.length) {
                                          const mappings =
                                            response?.payload?.fieldmappingdata_set.map((item) => ({
                                              id: item?.id,
                                              destination_platform_data_id: item
                                                ?.destination_platform_data?.id
                                                ? item?.destination_platform_data?.id + ''
                                                : '',
                                              source_platform_data_id: item
                                                ?.destination_platform_data?.id
                                                ? item?.source_platform_data?.id + ''
                                                : '',
                                              destination_platform_data_str:
                                                item.destination_platform_data_str ?? '',
                                              source_platform_data_str:
                                                item.source_platform_data_str ?? '',
                                            }))
                                          setShippingMethodMappings(mappings)
                                          let shadowFieldMappings = [...fieldMappings]
                                          let existingFieldMapping = shadowFieldMappings.find(
                                            (item) =>
                                              item.fieldCode === field_mapping.field_code &&
                                              item.groupCode === settingName
                                          )
                                          let existingFieldMappingIndex =
                                            shadowFieldMappings.findIndex(
                                              (item) =>
                                                item.fieldCode === field_mapping.field_code &&
                                                item.groupCode === settingName
                                            )

                                          if (existingFieldMapping) {
                                            existingFieldMapping.fieldMappingDataSet = mappings
                                            shadowFieldMappings[existingFieldMappingIndex] =
                                              existingFieldMapping
                                            setFieldMappings(shadowFieldMappings)
                                          }
                                        }
                                        setLoadingMappingFields(false)
                                      })
                                    }
                                  })
                                } else {
                                  let filteredMappings = copyMppings.filter((it, ind) => {
                                    if (ind !== index) {
                                      return it
                                    }
                                  })
                                  setShippingMethodMappings([...filteredMappings])
                                }
                              }}
                              className='btn btn-sm mt-2'
                              style={{color: 'red'}}
                            >
                              <FontAwesomeIcon icon={faDeleteLeft} style={{fontSize: '20px'}} />
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              })
            : null}
          {platformFields.sourcePlatformField && !field_mapping?.isDefault ? (
            <div className='d-flex justify-content-end'>
              <button
                className='btn btn-primary btn-sm me-3'
                type='button'
                onClick={() => {
                  let newItem: ShippingMethodMapping = {id: null}
                  if (platformFields.sourcePlatformField?.input_type === 'SELECT') {
                    newItem.source_platform_data_id = ''
                  } else {
                    newItem.source_platform_data_str = ''
                  }

                  if (platformFields.destinationPlatformField?.input_type === 'SELECT') {
                    newItem.destination_platform_data_id = ''
                  } else {
                    newItem.destination_platform_data_str = ''
                  }

                  let shadowArrayMapping = [...shippingMethodMappings]
                  shadowArrayMapping.push(newItem)

                  let shadowFieldMappings = [...fieldMappings]
                  let existingFieldMapping = shadowFieldMappings.find(
                    (item) => (item.fieldCode === field_mapping.field_code && item.groupCode === settingName )
                  )
                  let existingFieldMappingIndex = shadowFieldMappings.findIndex(
                    (item) => (item.fieldCode === field_mapping.field_code && item.groupCode === settingName )
                  )

                  if (existingFieldMapping) {
                    existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                    shadowFieldMappings[existingFieldMappingIndex] = existingFieldMapping
                    setFieldMappings(shadowFieldMappings)
                  } else {
                    setFieldMappings((pre) => {
                      return [
                        ...pre,
                        {
                          groupCode: settingName,
                          fieldCode: field_mapping.field_code,
                          savedSettingId: settingId,
                          savedMappingId: savedMapping?.id!,
                          destinationFieldId: platformFields.destinationPlatformField?.id!,
                          sourceFieldId: platformFields.sourcePlatformField?.id ?? null,
                          fieldMappingDataSet: shadowArrayMapping,
                        },
                      ]
                    })
                  }
                  setShippingMethodMappings([...shadowArrayMapping])
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>{' '}
            </div>
          ) : null}

          {/* { platformFields.sourcePlatformField || platformFields.destinationPlatformField ? 
                  <div className='d-flex justify-content-end'>
                    <button
                      type='button'
                      form='mapping_form'
                      className='btn btn-success btn-sm mt-2 me-3 align-self-end'
                      onClick={() => !platformFields.sourcePlatformField ? handleSingleValues(values.mappings, setSubmitting):   handleSaveShipmentMappings(values.mappings, setSubmitting)}
                    >
                      {loadingMappingSave || loadingLocationOption? 'Saving...' : 'Save Mapping'}
                    </button>
                  </div> : null } */}
        </div>
      </div>
    </div>
  )
}

export default MappingInput
