import { createAsyncThunk } from "@reduxjs/toolkit"
import AxiosClient from "../AxiosClient"


export const getSettingsOfSelectedPlatformAsync = createAsyncThunk(
    'platformSettings/getSettingsOfSelectedPlatformAsync',
    async function (params:{availablePlatformId:string, primaryPlatformId:number, platform_id: string}, toolkit) {
      return await AxiosClient('GET', `/available-platform/${params.availablePlatformId}/settings?related_available_platform=${params.primaryPlatformId}&platform_id=${params.platform_id}`, [], toolkit, [] )
    }
  )

  export const getSettingsOfPrimaryPlatformAsync = createAsyncThunk(
    'platformSettings/getSettingsOfPrimaryPlatformAsync',
    async function (params:{primaryAvailablePlatformId:number, selectedPlatformId:string, primaryPlatformId: number}, toolkit) {
      return await AxiosClient('GET', `/available-platform/${params.primaryAvailablePlatformId}/settings?type=MAIN_STORE_SETTING&related_available_platform=${params.selectedPlatformId}&platform_id=${params.primaryPlatformId}`, [], toolkit, [] )
    }
  )


  //api use in dynamic setting for get saved field mapping by their id
  export const getSavedFieldMappingsDataAsyncByMappingId = createAsyncThunk(
    'platformSettings/getSavedFieldMappingsDataAsyncByMappingId',
    async function (params:{mappingId:number}, toolkit) {
      return await AxiosClient('GET', `/api/field-mapping-with-setting/${params.mappingId}`, [], toolkit, [] )
    }
  )
 
  //new api use in dynamic setting for getting all saved field mappings by their ids
  export const getSavedFieldMappingsDataAsync = createAsyncThunk(
    'platformSettings/getSavedFieldMappingsDataAsyncByMappingId',
    async function (params:{field_mapping_ids:[]}, toolkit) {
      return await AxiosClient('GET', `/api/field-mapping-with-setting?field_mappings_list=[${params.field_mapping_ids}]`, [], toolkit, [] )
    }
  )

  /**
   * @description saveFieldMappingForSectionWiseAsync is used for "save" and "update" both
   */
  export const saveFieldMappingForSectionWiseAsync = createAsyncThunk(
    'platformSettings/saveFieldMappingForSectionWiseAsync',
    async function (params:{mappingId:number|null, payload:any}, toolkit) {
      if(params.mappingId){
        return await AxiosClient('POST', `/api/field-mapping-with-setting/${params.mappingId}`, params.payload, toolkit, [] )
      }else{
        return await AxiosClient('POST', `/api/field-mapping-with-setting`, params.payload, toolkit, [] )
      }
    }
  )

  export const saveFieldMappingsAsync = createAsyncThunk(
    'platformSettings/saveFieldMappingsAsync',
    async function (params:{field_mapping_ids:[], payload:any}, toolkit) {
        return await AxiosClient('POST', `/api/field-mapping-with-setting?field_mappings_list=[${params.field_mapping_ids}]`, params.payload, toolkit, [] )
    }
  )

  export const getAvailablePlatformAsync = createAsyncThunk(
    'platformSettings/getAvailablePlatformAsync',
    async function (params:{selectedAvailablePlatformId:string}, toolkit) {
      return await AxiosClient('GET', `/available-platform/${params.selectedAvailablePlatformId}`, [], toolkit, [] )
    }
  )